import { useState, useEffect, useCallback, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ButtonLoadMore from 'components/button-load-more/button-load-more'

import { getBikerType } from 'helpers'
import { useAuth, useDebounce } from 'hooks'
import { api2 } from 'services/api'
import colors from 'themes/colors'
import { IAgent } from 'types'
import { IPagination } from 'types/paginate'

import Button from '../button/Button'
import DelivererItem from '../deliverer-item/deliverer-item'
import EmptyMessage from '../empty-message/empty-message'
import Spinner from '../spinner/spinner'
import TextInputForm from '../text-input-form'

import './style.scss'

interface Props {
    title?: string
    isFix?: boolean
    closeClick?(): void
    onConfirmClick?(biker: IAgent | null | undefined): void
    selectedBiker?: IAgent | null
    setSelectedBiker?(biker: IAgent): void
    visible?: boolean
}

export const SearchDeliverersListing: React.FC<Props> = ({
    title,
    closeClick,
    onConfirmClick,
    selectedBiker,
    setSelectedBiker,
    isFix,
}) => {
    const { mall } = useAuth()
    const totalRef = useRef<number>(0)
    const pageRef = useRef<number>(1)
    const [search, setSearch] = useState('')
    const [deliverers, setDeliverers] = useState<IAgent[]>([])
    const [loading, setLoading] = useState(false)
    const [emptyMessage, setEmptyMessage] = useState('')
    const debouncedSearch = useDebounce(search, 500)
    const [loadMore, setLoadMore] = useState(false)

    const getDeliverers = useCallback(
        async ({ search = '', current_page = 1 } = {}) => {
            try {
                setLoading(true)
                setDeliverers([])
                setEmptyMessage('')

                const params: any = {}

                if (mall.type === 'private') {
                    params.on_fleet = mall.id
                    params.search = search
                } else {
                    if (!isFix) {
                        params.on_mall_queue = mall.id
                    }
                    params.logaroo_id = search
                }

                const { data } = await api2.get<IPagination<IAgent>>('/agents/to-route', {
                    params: {
                        current_page,
                        per_page: 100,
                        ...params,
                    },
                })
                pageRef.current = data.meta.paginate.current_page
                totalRef.current = data.meta.paginate.totals

                setDeliverers(data.items)

                if (!data.items.length) {
                    setEmptyMessage('Não foram encontrados entregadores aprovados na pesquisa')
                }
            } catch (error) {
                console.log({ error })
            } finally {
                setLoading(false)
            }
        },
        [mall]
    )

    const _loadMore = useCallback(async () => {
        setLoadMore(true)
        try {
            const { data } = await api2.get<IPagination<IAgent>>('/agents/to-route', {
                params: {
                    search: debouncedSearch,
                    current_page: pageRef.current + 1,
                    per_page: 100,
                    on_fleet: mall.type === 'private' ? mall.id : undefined,
                },
            })
            pageRef.current = data.meta.paginate.current_page
            totalRef.current = data.meta.paginate.totals
            setDeliverers([...deliverers, ...data.items])
        } catch (error) {
            console.log('error', error)
        }

        setLoadMore(false)
    }, [deliverers, debouncedSearch, mall])

    useEffect(() => {
        if (debouncedSearch) {
            getDeliverers({ search: debouncedSearch })
        } else {
            setDeliverers([])
            setEmptyMessage('Pesquise por um entregador autorizado no bolsão')
        }
    }, [debouncedSearch, getDeliverers])

    return (
        <div className="deliverers-listing-container">
            <div className="title-row">
                <div className="text">{title || 'Procure por um entregador'}</div>
                {closeClick && (
                    <div className="close" onClick={closeClick}>
                        <FontAwesomeIcon icon="times" />
                    </div>
                )}
            </div>

            <div className="deliverer-listing">
                <div className="search-input-container">
                    <TextInputForm
                        placeholder="Buscar entregador responsável"
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setSearch(value)}
                        value={search}
                        rightInsideIcon={<FontAwesomeIcon icon="search" style={{ marginTop: 8 }} />}
                    />

                    {loading && <Spinner />}
                    {emptyMessage.length > 0 && <EmptyMessage>{emptyMessage}</EmptyMessage>}
                </div>

                {deliverers.map((item, index) => (
                    <DelivererItem
                        key={index}
                        checkpointName={item.device?.checkpoint}
                        photoPath={`${item.avatar}?width=100`}
                        isSelected={item.id === selectedBiker?.id}
                        delivererName={`${item.name}`}
                        vehicleName={getBikerType(item.type)}
                        hasRoute={item.has_route}
                        onClick={() => setSelectedBiker && setSelectedBiker(item)}
                    />
                ))}

                <ButtonLoadMore
                    loading={loadMore}
                    visible={totalRef.current > 0 && deliverers.length < totalRef.current}
                    onClick={_loadMore}
                />
            </div>

            {onConfirmClick && (
                <Button
                    buttonText="Confirmar"
                    bgColor={colors.primary_color}
                    textColor="#fff"
                    width="100%"
                    onClick={() => onConfirmClick(selectedBiker)}
                />
            )}
        </div>
    )
}

const SearchDelivererModal: React.FC<Props> = ({ visible, closeClick, onConfirmClick }) => {
    return (
        <div className="search-deliverer-modal" style={{ display: visible ? 'flex' : 'none' }}>
            <SearchDeliverersListing closeClick={closeClick} onConfirmClick={onConfirmClick} />
        </div>
    )
}

export default SearchDelivererModal
