import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { format } from 'date-fns'

import { PerformanceHeader } from 'components/_reports'
import { PeriodOrdersTable2 } from 'components/_store-general-vision/period-orders-table2/period-orders-table2'

import { useUI } from 'contexts'
import { setSearchParams, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import { api2 } from 'services/api'
import { IPagination } from 'types/paginate'
import { IReportOrder } from 'types/report-order'

import { OverviewCard, AgentsCard, AreasCard, StoresCard } from './cards'
import { DaysCard } from './cards/days-card/days-card'
import {
    CardRow,
    PerformanceContent,
    PerformanceProblemCollectContainer,
    RowItem,
} from './performance-problem-collect.styled'

export default function PerformanceProblemCollect(): JSX.Element {
    const pageRef = useRef(1)
    const history = useHistory()
    const { mall, store, user } = useAuth()
    const { setSnackbar } = useUI()

    const [orders, setOrders] = useState<IReportOrder[]>([])
    const [loading, setLoading] = useState(true)
    const [filters, setFilters] = useState<any>()

    const hasReportStoresPermission = user?.isMallManager || user?.isManager || user?.isFinancial

    const _export = useCallback(async () => {
        try {
            setLoading(true)

            const response = await api2.get('order-problems', {
                responseType: 'blob',
                params: { ...filters, download: 'csv' },
            })

            const downloadURL = window.URL.createObjectURL(response.data)

            const timestamp = `${format(filters.start_date, 'DD-MM-YYYY')} a ${format(filters.end_date, 'DD-MM-YYYY')}`
            const filename = `${store.name || mall.name} COLETAS IMPRODUTIVAS - ${timestamp}.csv`

            const link = document.createElement('a')
            link.href = downloadURL
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
            setTimeout(() => {
                window.URL.revokeObjectURL(downloadURL)
            }, 100)
            setSnackbar({ message: 'Relatório Exportado', type: 'success' })
        } catch (error) {
            if (error?.response?.status !== 422) {
                setSnackbar({ message: showErrors(error), type: 'error' })
                return
            }
        } finally {
            setLoading(false)
        }
    }, [filters, mall.name, setSnackbar, store.name])

    const _getReports = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const result = await api2.get<IPagination<IReportOrder, any>>('order-problems', {
                params: { ...params, current_page: pageRef.current, per_page: 100 },
            })
            setOrders(state => {
                if (pageRef.current > 1) {
                    return [...state, ...result.data.items]
                }

                return result.data.items
            })
            pageRef.current = result.data.meta.paginate.current_page
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [])

    const _updateFilters = useCallback(() => {
        const query = new URLSearchParams(location.search)

        setFilters({
            mall_id: mall?.id,
            stores: user?.isShopkeeper
                ? [store?.id]
                : query.get('store_id')
                ? [Number(query.get('store_id'))]
                : undefined,
            start_date: query.get('start_date') || format(new Date(), 'YYYY-MM-DD'),
            end_date: query.get('end_date') || format(new Date(), 'YYYY-MM-DD'),
            start_time: query.get('start_time') || undefined,
            end_time: query.get('end_time') || undefined,
        })
    }, [mall?.id, store?.id, user?.isShopkeeper])

    const _loadMore = useCallback(() => {
        if (filters) {
            pageRef.current++
            _getReports(filters)
        }
    }, [_getReports, filters])

    const _applyFilter = useCallback(
        (filters: any) => {
            setSearchParams(filters, location, history)
            setTimeout(() => {
                _updateFilters()
            }, 500)
        },
        [_updateFilters, history]
    )

    useEffect(() => {
        if (filters) {
            _getReports(filters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    return (
        <PerformanceProblemCollectContainer>
            <PerformanceHeader
                title="Coleta Improdutiva"
                submitOnRender
                filters={filters}
                onFilterData={_applyFilter}
            />
            <PerformanceContent>
                <OverviewCard params={filters} />
                <CardRow>
                    <RowItem>
                        <AreasCard params={filters} />
                    </RowItem>
                    <RowItem>
                        <DaysCard params={filters} />
                    </RowItem>
                </CardRow>
                {hasReportStoresPermission && (
                    <CardRow>
                        <RowItem>
                            <StoresCard params={filters} />
                        </RowItem>
                        <RowItem>
                            <AgentsCard params={filters} />
                        </RowItem>
                    </CardRow>
                )}

                <PeriodOrdersTable2
                    filters={filters}
                    loading={loading}
                    onLoadMore={_loadMore}
                    orders={orders}
                    total={orders.length}
                    exportClick={_export}
                />
            </PerformanceContent>
        </PerformanceProblemCollectContainer>
    )
}
