import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Spinner from 'react-spinner-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cep from 'cep-promise'
import { format, subDays } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import OrderItemsTable from 'components/order-items-table/order-items-table'

import ButtonForm from '../../components/button-form'
import CheckboxInput from '../../components/checkbox-input'
import InputRadio from '../../components/input-radio'
import MapboxView, { DraggableMarker } from '../../components/mapbox-view'
import ModalLoading from '../../components/modal-loading'
import ModalMessage from '../../components/modal-message'
import OrderDetailInfo from '../../components/order-detail-info/order-detail-info'
import SearchResultListing from '../../components/search-result-listing/search-result-listing'
import SelectInputForm from '../../components/select-input-form'
import TextArea from '../../components/text-area'
import TextInputForm from '../../components/text-input-form'
import TextInputMask from '../../components/text-input-mask'
import TopRowDefault from '../../components/top-row-default/top-row-default'
import { objectIsEmpty, showErrors, formatToOrderItems, formatOrderItemsToString } from '../../helpers'
import { useAuth, useEventListener, useGeocodingSearch } from '../../hooks'
import api from '../../services/api'
import { Creators as UtilsActions } from '../../store/reducers/utils'

import './style.scss'

const RequestDelivery = ({ history }) => {
    const dispatch = useDispatch()

    const { basePath, mall, store } = useAuth()

    const [loading, toggleLoading] = useState(false)
    const [loadingSearch, toggleLoadingSearch] = useState(false)
    const [payments, setPayments] = useState([])
    const [salesChannels, setSalesChannels] = useState([])
    const [justifications, setJustifications] = useState([])
    const [fitBounds, setFitBounds] = useState(undefined)
    const [modalMessageData, setModalMessageData] = useState({
        title: '',
        message: '',
        textButton: null,
        onClose: () => {},
    })
    const [order, setOrder] = useState(null)
    const [hasSearched, setSearched] = useState(false)
    const [searchedOrders, setSearchedOrders] = useState([])

    const {
        isSubmitting,
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        setValues,
    } = useFormik({
        initialValues: {
            new_order: true,
            reference_id: '',
            sale_channel: '',
            payment_code: '',
            total_price: '',
            customer_id_number: '',
            customer_name: '',
            customer_email: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            landmark: '',
            lat: '',
            lng: '',
            location_changed: false,
            customer_phone: '',
            items: '',
            is_forecast: false,
            delivery_forecast: {
                date: '',
                hours: '',
            },
            justification_status: '',
            justification_content: '',
            birth: '',
        },
        validationSchema: Yup.object().shape({
            sale_channel: Yup.string().trim().required('Por favor, selecione um Canal de venda.'),
            payment_code: Yup.string().trim().required('Por favor, selecione uma Forma de pagamento.'),
            customer_name: Yup.string().trim().required('Por favor, insira o Nome do cliente'),
            customer_email: Yup.string().nullable().trim().email('Deve ser um email válido'),
            zipcode: Yup.string().trim().min(8, 'CEP deve ter 8 números').required('Por favor, insira o CEP.'),
            street: Yup.string().trim().required('Por favor, insira o Logradouro'),
            neighborhood: Yup.string().trim().required('Por favor, insira o Bairro'),
            number: Yup.string().trim().required('Por favor, insira o Número'),
            items: Yup.string().trim().required('Por favor, preencha com items do pedido'),
            location_changed: Yup.bool().oneOf([true], ''),
            customer_phone: Yup.string()
                .trim()
                .min(10, 'Telefone deve ter 11 números')
                .required('Por favor, insira o Telefone'),
            delivery_forecast: Yup.object().when('is_forecast', {
                is: is_forecast => is_forecast,
                then: schema =>
                    schema.shape({
                        date: Yup.date()
                            .min(subDays(new Date(), 1), 'Não é possível agendar um pedido em uma data passada.')
                            .required('Por favor, insira a Data'),
                        hours: Yup.string()
                            .trim()
                            .when('date', (date, schema) => {
                                return schema.test({
                                    test: hours => {
                                        if (hours) {
                                            const [hour, minutes] = hours?.split(':')
                                            date.setHours(hour)
                                            date.setMinutes(minutes)

                                            const currentDate = new Date()

                                            return date <= currentDate ? false : true
                                        } else {
                                            return false
                                        }
                                    },
                                    message: hours =>
                                        hours?.value
                                            ? 'Não é possível um pedido em horários passados.'
                                            : 'Por favor, insira o Horário',
                                })
                            }),
                    }),
            }),
            justification_status: Yup.string().trim().required('Por favor, selecione o tipo da Justificativa.'),
        }),

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)

                const delivery_forecast = values.is_forecast
                    ? `${values.delivery_forecast.date} ${values.delivery_forecast.hours}:00`
                    : undefined

                await api.post('painel/order/import', {
                    ...values,
                    is_forecast: undefined,
                    location_changed: undefined,
                    new_order: undefined,
                    sale_channel: values.sale_channel,
                    state: mall.address.city.state.uf,
                    city: mall.address.city.name,
                    neighborhood: values.neighborhood,
                    customer_id_number: values.customer_id_number
                        ? values.customer_id_number
                        : Math.random() * (999999 - 100000) + 100000,
                    items: formatToOrderItems(values.items),
                    delivery_forecast,
                    merchant_id: store.id,
                    origin: 'Logaroo Extranet',
                    status: values.is_forecast ? 1 : 3,
                    sub_total: values.total_price,
                    total_price: values.total_price,
                })

                dispatch(
                    UtilsActions.setModalMessageData({
                        isActive: true,
                        title: 'Entrega solicitada',
                        message: 'Entrega solicitada com sucesso',
                        textButton: 'Continuar',
                        onClose: () => history.push(basePath),
                    })
                )
            } catch (error) {
                console.log('handleSubmit', { error })

                dispatch(
                    UtilsActions.setModalMessageData({
                        isActive: true,
                        title: 'Erro',
                        message: showErrors(error),
                        textButton: 'Revisar alterações',
                    })
                )
            } finally {
                setSubmitting(false)
            }
        },
    })

    const getSelectData = useCallback(async () => {
        try {
            toggleLoading(true)

            const resPayments = await api.get('/painel/payments')
            setPayments(resPayments.data.items)

            const resSalesChannels = await api.get('/painel/sales-channels')
            setSalesChannels(resSalesChannels.data.items)

            const resJustifications = await api.get('/painel/dm-orders/justifications')
            setJustifications(resJustifications.data)
        } catch (error) {
            setModalMessageData({
                title: 'Erro',
                message: showErrors(error),
                onClose: () => history.push(basePath),
            })
        } finally {
            toggleLoading(false)
        }
    }, [history])

    useEffect(() => {
        getSelectData()
    }, [getSelectData])

    const searchOrders = useCallback(
        async searching => {
            try {
                setSearchedOrders([])
                setOrder(null)
                toggleLoadingSearch(true)
                setSearched(true)

                const { data } = await api.get(`/painel/dm-orders/${mall.id}/search`, {
                    params: {
                        merchant_id: store.id,
                        searching,
                    },
                })

                setSearchedOrders(data.items)
            } catch (error) {
                console.log({ error })
            } finally {
                toggleLoadingSearch(false)
            }
        },
        [store, mall]
    )

    const handleKeyPress = useCallback(
        async ({ key, target: { id, value } }) => {
            if (key === 'Enter' && id === 'reference_id') {
                searchOrders(value)
            }
        },
        [searchOrders]
    )

    useEventListener('keydown', handleKeyPress)

    const getAddressByCEP = async zipcode => {
        try {
            if (zipcode.length === 8) {
                setFieldTouched('zipcode', true)
                toggleLoading(true)

                const response = await cep(zipcode)

                setValues({
                    ...values,
                    neighborhood: response.neighborhood,
                    street: response.street,
                    zipcode: response.cep,
                })
            }
        } catch (error) {
            console.log({ error, message: error.message })
            setFieldError('zipcode', error.message)
        } finally {
            toggleLoading(false)
        }
    }

    const { getLatLngByAddress } = useGeocodingSearch({
        onSuccess: ({ lat, lng }) => {
            setValues({ ...values, lat, lng, location_changed: true })
        },
    })

    const onSelectOrder = order_id => {
        const orderSelected = searchedOrders.find(item => item.id === order_id)

        const salesChannel = salesChannels.find(item => item.name === orderSelected.reference_name)

        setValues({
            ...values,
            reference_id: orderSelected.reference_id,
            sale_channel: salesChannel?.id,
            payment_code: orderSelected.payment.id,
            total_price: orderSelected.total_price,
            customer_id_number: orderSelected.customer.id,
            customer_name: orderSelected.customer.name,
            customer_email: orderSelected.customer.email,
            zipcode: orderSelected.address.zipcode,
            street: orderSelected.address.street,
            number: orderSelected.address.number,
            complement: orderSelected.address.complement,
            neighborhood: orderSelected.address.neighborhood,
            landmark: orderSelected.address.landmark,
            lat: orderSelected.address.lat,
            lng: orderSelected.address.lng,
            location_changed: true,
            customer_phone: orderSelected.customer.phone,
            items: orderSelected?.items?.length > 0 ? formatOrderItemsToString(orderSelected.items) : '',
            birth: orderSelected.birth,
        })

        setSearchedOrders([])
        setSearched(false)

        setOrder(orderSelected)
    }

    useEffect(() => {
        if (isNaN(values.lat) || isNaN(values.lng)) return

        setFitBounds([
            [mall.address.lng, mall.address.lat],
            [values.lng, values.lat],
        ])
    }, [values.lat, values.lng, mall.address.lat, mall.address.lng])

    return (
        <div className="page-container">
            <ModalLoading visible={isSubmitting || loading} />

            <ModalMessage
                isActive={modalMessageData.message}
                title={modalMessageData.title}
                message={modalMessageData.message}
                textButton={modalMessageData.textButton}
                onClose={() => {
                    setModalMessageData({})
                }}
            />

            <TopRowDefault title="Novo pedido" onBackButtonClick={() => history.push(basePath)} />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="request-delivery-left-column">
                        <div className="request-delivery-form-container">
                            <div className="title">Tipo de pedido</div>

                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <InputRadio
                                    id="existing-order"
                                    checked={!values.new_order}
                                    onClick={() => setFieldValue('new_order', false)}
                                    label="Pedido existente"
                                />

                                <InputRadio
                                    id="new-order"
                                    checked={values.new_order}
                                    onClick={() => {
                                        setFieldValue('new_order', true)
                                        setSearchedOrders([])
                                        setSearched(false)
                                    }}
                                    label="Novo pedido"
                                />
                            </div>

                            <TextInputForm
                                id={!values.new_order ? 'reference_id' : ''}
                                label="Número do pedido"
                                value={values.reference_id}
                                onChange={({ target: { value } }) => setFieldValue('reference_id', value)}
                                rightInsideIcon={
                                    !values.new_order && loadingSearch ? (
                                        <Spinner color={'#000'} size={20} stroke={2} radius={20} />
                                    ) : !values.new_order ? (
                                        <div onClick={() => searchOrders(values.reference_id)}>
                                            <FontAwesomeIcon icon="search" />
                                        </div>
                                    ) : null
                                }
                            />

                            {searchedOrders.length ? (
                                <SearchResultListing
                                    data={searchedOrders}
                                    onClick={order_id => onSelectOrder(order_id)}
                                />
                            ) : hasSearched && !loadingSearch ? (
                                <div className="empty-message-container">
                                    <span className="empty-message">Nenhum resultado encontrado.</span>
                                </div>
                            ) : null}

                            {!values.new_order && order && (
                                <OrderDetailInfo
                                    deliveryOrigin={order?.delivery_origin}
                                    orderId={order?.id}
                                    route={order?.route}
                                    hasProblem={order?.has_problem}
                                    agent={order?.biker}
                                    merchant={order?.merchant}
                                    attachments={order?.attachments}
                                    storeSlug={order.merchant.category}
                                    collect={order?.collect}
                                    restaurantName={order.merchant.name}
                                    orderNumber={order.reference_id}
                                    orderValue={order.total_price}
                                    orderClient={order.customer.name}
                                    orderStatus={order.status}
                                    orderItems={order.items}
                                    problem={order.problem}
                                    type={order.type}
                                    orderAddress={order.address}
                                />
                            )}

                            {values.new_order && (
                                <>
                                    <SelectInputForm
                                        label="Canal de venda*"
                                        data={salesChannels}
                                        onChange={({ target: { value } }) => setFieldValue('sale_channel', value)}
                                        value={values.sale_channel}
                                        msgErro={touched.sale_channel && errors.sale_channel}
                                    />

                                    <SelectInputForm
                                        label="Forma de pagamento*"
                                        data={payments}
                                        value={values.payment_code}
                                        onChange={({ target: { value } }) => setFieldValue('payment_code', value)}
                                        msgErro={touched.payment_code && errors.payment_code}
                                    />

                                    <TextInputForm
                                        label="Valor do pedido*"
                                        type="currency"
                                        maxLength={13}
                                        value={values.total_price}
                                        onChange={value => setFieldValue('total_price', value)}
                                        msgErro={touched.total_price && errors.total_price}
                                    />

                                    <div className="title" style={{ marginTop: '30px' }}>
                                        Informações do cliente
                                    </div>

                                    <TextInputForm
                                        label="ID do cliente"
                                        value={values.customer_id_number}
                                        onChange={({ target: { value } }) => setFieldValue('customer_id_number', value)}
                                    />

                                    <TextInputForm
                                        label="Nome do cliente*"
                                        value={values.customer_name}
                                        onChange={({ target: { value } }) => setFieldValue('customer_name', value)}
                                        msgErro={touched.customer_name && errors.customer_name}
                                    />

                                    <TextInputForm
                                        label="Email do cliente"
                                        value={values.customer_email}
                                        onChange={({ target: { value } }) => setFieldValue('customer_email', value)}
                                        msgErro={touched.customer_email && errors.customer_email}
                                    />

                                    <TextInputMask
                                        label="Telefone*"
                                        mask="(99) 99999-9999"
                                        value={values.customer_phone}
                                        onChange={({ target: { value } }) =>
                                            setFieldValue('customer_phone', value.replace(/\D/g, ''))
                                        }
                                        msgErro={touched.customer_phone && errors.customer_phone}
                                    />

                                    <div className="title" style={{ marginTop: '30px' }}>
                                        Endereço
                                    </div>

                                    <TextInputMask
                                        label="CEP*"
                                        mask="99999-999"
                                        value={values.zipcode}
                                        onChange={({ target: { value } }) => {
                                            getAddressByCEP(value.replace(/\D/g, ''))
                                            setFieldValue('zipcode', value.replace(/\D/g, ''))
                                        }}
                                        msgErro={touched.zipcode && errors.zipcode}
                                    />

                                    <TextInputForm
                                        label="Bairro*"
                                        value={values.neighborhood}
                                        onChange={({ target: { value } }) => setFieldValue('neighborhood', value)}
                                        msgErro={touched.neighborhood && errors.neighborhood}
                                    />

                                    <TextInputForm
                                        label="Logradouro*"
                                        value={values.street}
                                        onChange={({ target: { value } }) => setFieldValue('street', value)}
                                        msgErro={touched.street && errors.street}
                                    />

                                    <div className="grouped-input-container">
                                        <div style={{ width: '20%' }}>
                                            <TextInputForm
                                                label="Número*"
                                                value={values.number}
                                                onChange={({ target: { value } }) => setFieldValue('number', value)}
                                                msgErro={touched.number && errors.number}
                                            />
                                        </div>

                                        <div style={{ width: '70%' }}>
                                            <TextInputForm
                                                label="Complemento"
                                                value={values.complement}
                                                onChange={({ target: { value } }) => setFieldValue('complement', value)}
                                                msgErro={touched.complement && errors.complement}
                                            />
                                        </div>
                                    </div>

                                    <TextInputForm
                                        label="Referência"
                                        value={values.landmark}
                                        onChange={({ target: { value } }) => setFieldValue('landmark', value)}
                                        msgErro={touched.landmark && errors.landmark}
                                    />

                                    {values.street && values.number && (
                                        <ButtonForm
                                            buttonText="Localizar no mapa"
                                            onClick={() => {
                                                const { city } = mall.address

                                                getLatLngByAddress({
                                                    city: city.name,
                                                    state: city.state.uf,
                                                    zipcode: values.zipcode,
                                                    neighborhood: values.neighborhood,
                                                    street: values.street,
                                                    number: values.number,
                                                })
                                            }}
                                        />
                                    )}

                                    <div className="title" style={{ marginTop: '30px' }}>
                                        Informações do pedido
                                    </div>

                                    <OrderItemsTable orderItems={formatToOrderItems(values.items)} />

                                    <TextArea
                                        label="Informações do pedido"
                                        rows={5}
                                        value={values.items}
                                        onChange={({ target: { value } }) => setFieldValue('items', value)}
                                        msgErro={touched.items && errors.items}
                                    />
                                </>
                            )}

                            <div className="title" style={{ marginTop: '30px' }}>
                                Agendamento
                            </div>

                            <CheckboxInput
                                id="is_forecast"
                                label="Entrega agendada"
                                onChange={({ target: { checked } }) => setFieldValue('is_forecast', checked)}
                                checked={values.is_forecast}
                            />

                            {values.is_forecast && (
                                <div className="grouped-input-container">
                                    <div style={{ width: '47%' }}>
                                        <TextInputForm
                                            label="Data*"
                                            type="date"
                                            value={values.delivery_forecast?.date}
                                            onChange={({ target: { value } }) =>
                                                setFieldValue('delivery_forecast.date', value)
                                            }
                                            msgErro={touched.delivery_forecast?.date && errors.delivery_forecast?.date}
                                        />
                                    </div>

                                    <div style={{ width: '47%' }}>
                                        <TextInputForm
                                            label="Hora*"
                                            type="time"
                                            value={values.delivery_forecast?.hours}
                                            onChange={({ target: { value } }) =>
                                                setFieldValue('delivery_forecast.hours', value)
                                            }
                                            msgErro={
                                                touched.delivery_forecast?.hours && errors.delivery_forecast?.hours
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="title" style={{ marginTop: '30px' }}>
                                Justificativa
                            </div>

                            <SelectInputForm
                                label="Tipo*"
                                data={justifications}
                                value={values.justification_status}
                                onChange={({ target: { value } }) => setFieldValue('justification_status', value)}
                                msgErro={touched.justification_status && errors.justification_status}
                            />

                            <TextArea
                                label="Especifique o motivo"
                                value={values.justification_content}
                                onChange={({ target: { value } }) => setFieldValue('justification_content', value)}
                                msgErro={touched.justification_content && errors.justification_content}
                            />
                        </div>

                        <ButtonForm
                            buttonText="Solicitar envio"
                            type="submit"
                            onClick={() => {
                                if (!objectIsEmpty(errors) || !values.location_changed) {
                                    setModalMessageData({
                                        title: 'Erro',
                                        message: !values.location_changed
                                            ? 'Selecione a localização no mapa'
                                            : 'Preencha a informações corretamente',
                                        textButton: 'Revisar alterações',
                                    })
                                }

                                if (values.new_order) {
                                    setFieldValue('birth', format(new Date(), 'YYYY-MM-DD HH:mm:ss'))
                                }

                                handleSubmit()
                            }}
                            style={{ width: '100%', marginBottom: 50 }}
                        />
                    </div>

                    <div className="right-column">
                        <MapboxView fitBounds={fitBounds} fitBoundsOptions={{ maxZoom: 18 }}>
                            <DraggableMarker
                                coordinates={values.lat && values.lng && [values.lng, values.lat]}
                                onDragEnd={({ lngLat: { lat, lng } }) => setValues({ ...values, lat, lng })}
                            />
                        </MapboxView>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestDelivery
