import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import Button from 'components/button/Button'
import OrderItemsTable from 'components/order-items-table/order-items-table'

import colors from 'themes/colors'

import './order-detail-info.style.scss'

import { getStatusOrder, hasOrderItemsDefaultFormat } from '../../helpers'

import { FileItem } from 'components/file-item/file-item'

import { useCallback, useRef } from 'react'

import { ModalFile } from 'modals/modal-file/modal-file'

import { TrackerCode } from 'components/tracker-code/tracker-code'
import { AgentCard } from 'components/agent-card/agent-card'
import { AccessComponent } from 'components/access-component/access-component'

import { ModalChangeCustomerName, ModalChangePaymentType } from 'modals'

import { TagProblem } from 'components/tag-problem/tag-problem'

const OrderDetailInfo = ({
    performance,
    orderNumber,
    orderValue,
    restaurantName,
    orderChannel,
    orderPayment,
    orderClient,
    hasProblem,
    problems,
    code,
    deliveryForecast,
    rescheduleClick,
    orderItems,
    orderAddress,
    observation,
    problem,
    route,
    agent,
    orderId,
    type,
    orderStatus,
    originId = null,
    deliveryOrigin = null,
    merchant = null,
    attachments = null,
    collect = null,
    orderChannelId = null,
    orderPaymentId = null,
    refresh = () => {},
}) => {
    const modalChangePaymentTypeRef = useRef()
    const modalChangeCustomerNameRef = useRef()
    const modalFileRef = useRef()

    const _refresh = useCallback(() => {
        if (refresh) {
            refresh()
        }
    }, [refresh])

    const displayJsonItems = items => {
        return items?.map((item, index) => (
            <div className="value items-description" key={index}>
                {typeof item === 'object' && (
                    <div className="item">
                        <div className="nome">{JSON.stringify(item, null, '\n')}</div>
                    </div>
                )}
            </div>
        ))
    }

    const displayStringItems = items => {
        return items?.map((item, index) => (
            <div className="value items-description" key={index}>
                <div className="item">
                    <div className="nome">{item}</div>
                </div>
            </div>
        ))
    }

    const _openFile = useCallback(attachment => {
        modalFileRef.current?.show({ attachment })
    }, [])

    const _changePaymentMethod = useCallback(() => {
        modalChangePaymentTypeRef?.current.show({
            orderId,
            orderPaymentId,
            orderChannelId,
        })
    }, [orderId, orderPaymentId, orderChannelId])

    const _changeCustomerName = useCallback(() => {
        modalChangeCustomerNameRef?.current.show({ orderId, customerName: orderClient })
    }, [orderId, orderClient])

    return (
        <>
            <div className="order-detail-info-container">
                {performance ? (
                    <>
                        <div className="info-row">
                            {!!performance?.production_time && (
                                <div className="info-item">
                                    <div className="title">Tempo de produção</div>
                                    <div className="value performance">{performance?.production_time || '-'}</div>
                                </div>
                            )}
                            {!!performance?.collect_time && (
                                <div className="info-item">
                                    <div className="title">Tempo de coleta</div>
                                    <div className="value performance">{performance?.collect_time || '-'}</div>
                                </div>
                            )}
                        </div>
                        <div className="info-row">
                            {!!performance?.route_time && (
                                <div className="info-item">
                                    <div className="title">Tempo de rota</div>
                                    <div className="value performance">{performance?.route_time || '-'}</div>
                                </div>
                            )}
                            {!!performance?.delivery_time && (
                                <div className="info-item">
                                    <div className="title">Tempo de entrega</div>
                                    <div className="value performance">{performance?.delivery_time || '-'}</div>
                                </div>
                            )}
                        </div>
                        <div className="info-row">
                            {!!performance?.total_time && (
                                <div className="info-item">
                                    <div className="title">Tempo total</div>
                                    <div className="value performance">{performance?.total_time || '-'}</div>
                                </div>
                            )}
                        </div>
                        {type === 'takeout' && (
                            <div className="info-row">
                                <div className="info-item">
                                    <div className="title">Entrega</div>
                                    <div className="value performance">Retirado no Balcão</div>
                                </div>
                            </div>
                        )}
                    </>
                ) : null}
                <AccessComponent roles={['owner', 'staff']} type="hide">
                    <>{agent && <AgentCard agent={agent} route={route} />}</>
                </AccessComponent>
                {!!orderId && <TrackerCode orderId={orderId} />}

                <div className="info-item">
                    <div className="title">Loja</div>
                    <div className="value restaurant">{restaurantName}</div>
                </div>
                <div className="info-row">
                    <div className="info-item">
                        <div className="title">Status do Pedido</div>
                        <div className="value">{getStatusOrder(orderStatus)}</div>
                    </div>
                    <div className="info-item">
                        <TagProblem hasProblem={hasProblem} problems={problems} />
                    </div>
                </div>
                <div className="info-row">
                    <div className="info-item">
                        <div className="title">Número do Pedido</div>
                        <div className="value">{orderNumber}</div>
                    </div>
                    <div className="info-item">
                        <div className="title">Valor do pedido</div>
                        <div className="value">R$ {orderValue}</div>
                    </div>
                </div>

                <div className="info-row">
                    <div className="info-item">
                        <div className="title">Canal de venda</div>
                        <div className="value">{orderChannel}</div>
                    </div>
                    <div className="info-item">
                        <div className="title">
                            <div className="edit-item-action">
                                Forma de pagamento
                                {orderChannelId && (
                                    <div className="action-content">
                                        <FontAwesomeIcon
                                            className="action-icon"
                                            icon="edit"
                                            onClick={_changePaymentMethod}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="value">{orderPayment}</div>
                    </div>
                </div>

                <div className="info-row wrap-row">
                    <div className="info-item">
                        <div className="title">
                            <div className="edit-item-action">
                                Cliente
                                {orderChannelId && (
                                    <div className="action-content">
                                        <FontAwesomeIcon
                                            className="action-icon"
                                            icon="edit"
                                            onClick={_changeCustomerName}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="value">{orderClient}</div>
                    </div>
                    {orderChannel === 'Ifood' && (
                        <div className="info-item">
                            <>
                                <div className="title">Identificador de origem</div>
                                <div className="value">{originId || 'Não Informado'}</div>
                            </>
                        </div>
                    )}
                    <div className="info-item">
                        {orderChannel === 'Ifood' && (
                            <>
                                <div className="title">Código de entrega</div>
                                <div className="value">{code || 'Não Informado'}</div>
                            </>
                        )}
                    </div>
                </div>
                <>
                    <div className="info-row">
                        <div className="info-item">
                            <div className="title">Local de coleta</div>
                            <div className="value">{deliveryOrigin?.name || merchant?.name}</div>
                        </div>
                        <div className="info-item">
                            {deliveryForecast && Number(orderStatus) === 1 ? (
                                <>
                                    <div className="title">Previsão de entrega</div>
                                    <div className="value delivery-forecast" onClick={rescheduleClick}>
                                        {`${format(deliveryForecast, 'DD/MM/YYYY')} às ${format(
                                            deliveryForecast,
                                            'HH:mm'
                                        )}`}{' '}
                                        <FontAwesomeIcon icon="edit" color={colors.light_primary_color} />
                                    </div>
                                </>
                            ) : Number(orderStatus) === 3 ? (
                                <>
                                    <div className="title">Previsão de entrega</div>
                                    <div className="value">
                                        <Button
                                            bgColor={colors.light_primary_color}
                                            textColor="white"
                                            buttonText="Reagendar"
                                            onClick={rescheduleClick}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="info-row">
                        <div className="info-item">
                            <div className="title">Requer confirmação de coleta?</div>
                            <div className="value">{Number(collect?.confirmation_required) ? 'SIM' : 'Não'}</div>
                        </div>
                        {!!collect?.observations && (
                            <div className="info-item">
                                <div className="title">Observações sobre a coleta</div>
                                <div className="value">{collect?.observations}</div>
                            </div>
                        )}
                    </div>
                    <div className="info-row">
                        <div className="info-item">
                            <div className="title">Requer confirmação de entrega?</div>
                            <div className="value">{Number(collect?.confirmation_delivery) ? 'SIM' : 'Não'}</div>
                        </div>
                        <div className="info-item">
                            <div className="title">Requer confirmação de problema?</div>
                            <div className="value">{Number(collect?.confirmation_fail) ? 'SIM' : 'Não'}</div>
                        </div>
                    </div>
                    {!!attachments?.request_collect?.length && (
                        <div className="info-row">
                            <div className="info-item">
                                <div className="title">Anexos</div>
                                <div className="list">
                                    {attachments?.request_collect.map((item, i) => (
                                        <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                    ))}
                                </div>
                            </div>
                            <div className="info-item"></div>
                        </div>
                    )}
                    <div className="info-row">
                        {attachments?.collect?.length > 0 && (
                            <div className="info-item">
                                <div className="title">Comprovante de coleta</div>
                                <div className="value">
                                    {attachments.collect.map((item, i) => (
                                        <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                    ))}
                                </div>
                            </div>
                        )}
                        {attachments?.delivery?.length > 0 && (
                            <div className="info-item">
                                <div className="title">Comprovante de entrega</div>
                                <div className="value">
                                    {attachments.delivery.map((item, i) => (
                                        <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                    ))}
                                </div>
                            </div>
                        )}
                        {attachments?.fail?.length > 0 && (
                            <div className="info-item">
                                <div className="title">Comprovante de problema</div>
                                <div className="value">
                                    {attachments.fail.map((item, i) => (
                                        <FileItem key={i} isView attachment={item} onClick={_openFile} />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </>

                {problem && problem.type === 'cancel' && (
                    <div className="info-item">
                        <div className="title red">Motivo do cancelamento</div>
                        <div className="value">{problem.reason}</div>
                    </div>
                )}

                <div className="info-item">
                    <div className="title">Endereço</div>
                    <div className="value">{`${orderAddress.zipcode} - ${orderAddress.neighborhood}`}</div>
                    <div className="value">
                        {orderAddress.street}, {orderAddress.number}
                    </div>
                    <div className="value">{orderAddress?.complement || ''}</div>
                    {orderAddress?.landmark && (
                        <small className="value">Referência: {orderAddress?.landmark || ''}</small>
                    )}
                </div>
                {observation && (
                    <div className="info-item">
                        <div className="title">Observação</div>
                        <div className="value">{observation}</div>
                    </div>
                )}

                <div className="info-item">
                    <div className="title">Descrição do Pedido</div>
                    <div>
                        {Array.isArray(orderItems) &&
                        orderItems?.every(item => typeof item === 'object' && hasOrderItemsDefaultFormat(item)) ? (
                            <OrderItemsTable orderItems={orderItems} />
                        ) : orderItems?.every(item => typeof item === 'string') ? (
                            displayStringItems(orderItems)
                        ) : (
                            displayJsonItems(orderItems)
                        )}
                    </div>
                </div>
            </div>
            <ModalFile ref={modalFileRef} />
            <ModalChangePaymentType ref={modalChangePaymentTypeRef} refresh={_refresh} />
            <ModalChangeCustomerName ref={modalChangeCustomerNameRef} refresh={_refresh} />
        </>
    )
}

export default OrderDetailInfo
