import { useMemo, useState } from 'react'
import { Marker, Popup } from 'react-mapbox-gl'

import { format, differenceInMinutes } from 'date-fns'

import { MarkerCollect, PopUpContent } from 'components/marker-collect/marker-collect'
import { MarkerOrder } from 'components/marker-order/marker-order'
import { TagProblem } from 'components/tag-problem/tag-problem'

import { getColorFromRoute } from 'helpers/getColorFromRoute'

import { formatAddress, getStatusTypeReadyDeliver, getStatusMarker } from '../../../helpers'
import { routeColors } from '../../../utils'
import { MarkerSatellite, PopUpContentSatellite } from '../marker-satellite/marker-satellite'

import {
    Row,
    ContainerPopup,
    StoreName,
    ContainerTopInfo,
    Circle,
    ContainerStatus,
    PopupInfo,
    PopupTopInfo,
    ContainerTotal,
    Icon,
    Line,
    AcceptButton,
    ButtonText,
    FooterPopup,
    ContainerClose,
    MapImage,
} from './route-orders-markers'

const RouteOrdersMarkers = ({
    routeOrders = null,
    configs = null,
    ordersToRoute = null,
    onOrderClick = null,
    insertOnRoute = null,
    seeDetails = null,
    onHoverRouteIdByOrder = null,
    routeId = null,
}) => {
    const orders = ordersToRoute || routeOrders
    const [popupItem, setPopupItem] = useState(null)
    const [popupCollect, setPopupCollect] = useState(null)
    const [popupSatellite, setPopupSatellite] = useState(null)

    const origins = useMemo(() => {
        if (!orders) {
            return []
        }

        let origins = orders
            .filter(
                item =>
                    !!item.delivery_origin && item.delivery_origin?.address?.lng && item.delivery_origin?.address?.lat
            )
            .map(item => ({
                ...item.delivery_origin,
                orders: [],
            }))

        orders.forEach(item => {
            const origin = origins.find(x => x.id === item.delivery_origin?.id)
            if (origin) {
                origin.orders.push(item)
            }
        })
        return origins
    }, [orders])

    const satellites = useMemo(() => {
        if (!orders) {
            return []
        }

        let satellites = orders.filter(item => !item.delivery_origin && !!item.merchant.is_satellite)
        return satellites
    }, [orders])

    const getOrderMarker = order => {
        if (isOrderInRouteOrders(order.id) && Number(order.status) < 8) {
            const index = routeOrders.findIndex(item => item.id === order.id)
            let backgroundColor
            if (routeId) {
                backgroundColor = getColorFromRoute(routeId)
            } else {
                backgroundColor = routeColors[index % routeColors.length]
            }

            return (
                <div className="route-order-pin" style={{ backgroundColor }}>
                    <span className="text">{index + 1}</span>
                </div>
            )
        } else {
            return (
                <MarkerOrder
                    isCollect={!!order?.delivery_origin || !!order.merchant.is_satellite}
                    type={getStatusMarker(order?.total_time?.minutes, configs, getStatusTypeReadyDeliver)}
                />
            )
        }
    }

    const isOrderInRouteOrders = order_id => {
        return routeOrders && routeOrders.some(item => item.id === order_id)
    }

    const setOrderClicked = order => {
        if (onOrderClick) {
            onOrderClick(order.id)
        }

        setPopupItem(popupItem?.id === order.id ? null : order)
    }

    return (
        <>
            {orders?.map(order => (
                <Marker
                    key={order.id}
                    style={{ cursor: 'pointer' }}
                    anchor="center"
                    coordinates={[order.address.lng, order.address.lat]}
                    onClick={() => setOrderClicked(order)}
                    onMouseEnter={onHoverRouteIdByOrder ? () => onHoverRouteIdByOrder(routeId) : null}
                    onMouseLeave={onHoverRouteIdByOrder ? () => onHoverRouteIdByOrder(null) : null}
                >
                    {getOrderMarker(order)}
                </Marker>
            ))}
            {origins.map(item => (
                <Marker
                    key={`collect-${getRandomInt()}-${item.id}`}
                    style={{ cursor: 'pointer' }}
                    anchor="bottom"
                    coordinates={[item.address.lng, item.address.lat]}
                    onClick={() => setPopupCollect(item)}
                >
                    <MarkerCollect />
                </Marker>
            ))}
            {satellites.map(item => (
                <Marker
                    key={`satellite-${getRandomInt()}-${item.id}`}
                    style={{ cursor: 'pointer' }}
                    anchor="bottom"
                    coordinates={[item.merchant.address.lng, item.merchant.address.lat]}
                    onClick={() => setPopupSatellite(item)}
                >
                    <MarkerSatellite />
                </Marker>
            ))}

            {popupItem && (
                <Popup
                    coordinates={[popupItem.address.lng, popupItem.address.lat]}
                    offset={{
                        'bottom-left': [0, -38],
                        bottom: [0, -38],
                        'bottom-right': [0, -38],
                    }}
                >
                    <ContainerPopup>
                        <ContainerTopInfo>
                            <Row>
                                <PopupTopInfo>{getTitlePopup(popupItem)}</PopupTopInfo>
                                <ContainerStatus>
                                    <Circle color={getStatusColor(popupItem.status)} />
                                    <PopupTopInfo uppercase>{getStatusName(popupItem.status)}</PopupTopInfo>
                                </ContainerStatus>
                            </Row>
                            <ContainerClose onClick={() => setPopupItem(null)}>
                                <Icon icon="times" />
                            </ContainerClose>
                        </ContainerTopInfo>
                        <TagProblem hasProblem={popupItem.has_problem} problems={popupItem.problems} />
                        <StoreName>{popupItem.merchant.name}</StoreName>
                        <PopupInfo>{popupItem.customer?.name}</PopupInfo>
                        <PopupInfo> {formatAddress(popupItem.address)}</PopupInfo>
                        {popupItem.status === '1' && popupItem.delivery_forecast ? (
                            <ContainerTotal>
                                <Icon icon="calendar" />
                                <PopupInfo>{format(popupItem.delivery_forecast, 'DD/MM/YYYY HH:mm')}</PopupInfo>
                            </ContainerTotal>
                        ) : (
                            <ContainerTotal>
                                <Icon icon="clock" />
                                <PopupInfo>Total: {totalMinutes(popupItem.birth)}</PopupInfo>
                            </ContainerTotal>
                        )}
                        {!!popupItem?.delivery_origin?.name && (
                            <ContainerTotal>
                                <MapImage />
                                <PopupInfo>Coleta: {popupItem.delivery_origin.name}</PopupInfo>
                            </ContainerTotal>
                        )}
                        {!popupItem.has_problem && (
                            <>
                                {!isOrderInRouteOrders(popupItem.id) && (
                                    <AcceptButton onClick={() => insertOnRoute(popupItem)}>
                                        Adicionar como próximo
                                    </AcceptButton>
                                )}
                            </>
                        )}
                        <FooterPopup onClick={() => seeDetails && seeDetails(popupItem.id)}>
                            <Line />
                            <ButtonText>Ver detalhes da rota</ButtonText>
                            <Line />
                        </FooterPopup>
                    </ContainerPopup>
                </Popup>
            )}
            {popupCollect ? (
                <Popup
                    style={{ visibility: 'visible' }}
                    coordinates={[popupCollect.address.lng, popupCollect.address.lat]}
                    offset={{
                        'bottom-left': [0, -38],
                        bottom: [0, -38],
                        'bottom-right': [0, -38],
                    }}
                >
                    <PopUpContent origin={popupCollect} onClose={() => setPopupCollect(null)} />
                </Popup>
            ) : null}

            {popupSatellite?.merchant ? (
                <Popup
                    style={{ visibility: 'visible' }}
                    coordinates={[popupSatellite.merchant.address.lng, popupSatellite.merchant.address.lat]}
                    offset={{
                        'bottom-left': [0, -38],
                        bottom: [0, -38],
                        'bottom-right': [0, -38],
                    }}
                >
                    <PopUpContentSatellite satellite={popupSatellite} onClose={() => setPopupSatellite(null)} />
                </Popup>
            ) : null}
        </>
    )
}

function getStatusName(status) {
    if (status === '0') {
        return 'Pedido foi cancelado'
    }

    if (status === '1') {
        return 'Pedido foi capturado'
    }

    if (status === '2') {
        return 'Pedido aguardando moderação'
    }

    if (status === '3') {
        return 'Pedido em produção'
    }

    if (status === '4') {
        return 'Pedido pronto p/ coleta'
    }

    if (status === '5') {
        return 'Pedido coletado'
    }

    if (status === '6') {
        return 'Pedido saiu para entrega'
    }

    if (status === '7') {
        return 'Pedido chegou ao cliente'
    }

    if (status === '8') {
        return 'Pedido entregue ao cliente'
    }

    if (status === '9') {
        return 'Comprovante de pagamento entregue ao lojista'
    }

    if (['10', '10B', '10C', '10D', '10E', '10F', '10G', '10H'].includes(status)) {
        return 'Problema'
    }

    return ''
}

function getStatusColor(status) {
    if (['1', '2', '3', '4'].includes(status)) {
        return '#F1C40F'
    }
    if (['5', '6', '7', '8'].includes(status)) {
        return '#FF8E21'
    }
    if (['9', '11'].includes(status)) {
        return '#04B42D'
    }
    if (['0', '10', '10B', '10C', '10D', '10E', '10F', '10G', '10H'].includes(status)) {
        return '#B3050D'
    }
    return '#999999'
}

function getTitlePopup(order) {
    return `#${order.reference_id || order.id} - ${order.sales_channel?.name || ''}`.toUpperCase()
}

function totalMinutes(birth) {
    if (birth) {
        return `${differenceInMinutes(new Date(), birth)} min`
    }

    return ''
}

function getRandomInt() {
    let min = Math.ceil(0)
    let max = Math.floor(9999)
    return Math.floor(Math.random() * (max - min)) + min
}

export default RouteOrdersMarkers
