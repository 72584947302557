import styled from 'styled-components'

export const PerformanceProblemCollectContainer = styled.div`
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
`

export const PerformanceContent = styled.div`
    background-color: #e8e8e8;
    flex: 1;
    padding: 20px;
`

export const CardRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        justify-content: space-between;
    }
`

export const RowItem = styled.div`
    height: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 49%;
    }
`
