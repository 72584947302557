import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const ActionModalContainer = styled.div<{ isActive?: boolean }>`
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.actionModalIndex};
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    transform: translateX(${({ isActive }) => (isActive ? '0%' : '-200%')});
`

export const ActionModalBox = styled.div<{ isActive?: boolean }>`
    width: 80%;
    height: auto;
    margin: auto;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
    transition: 400ms;
    transform: translateY(${({ isActive }) => (isActive ? '0%' : '-200%')});
    border-radius: 12px;
    text-align: center;
    padding: 40px 20px;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 400px;
    }
`

export const ActionTopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    height: 40px;
`

export const CloseButton = styled(FontAwesomeIcon).attrs({
    icon: faTimes,
})`
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
`

export const ModalIcon = styled(FontAwesomeIcon)<{ icon: IconProp; style: React.CSSProperties }>`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 38px;
    margin-bottom: 20px;
`

export const ModalTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    width: 85%;
    margin: auto;
    margin-bottom: 10px;
`

export const ModalSubtitle = styled.div`
    font-size: 14px;
    width: 85%;
    margin: auto;
    margin-bottom: 40px;
`

export const ButtonRow = styled.div<{ rowMode?: boolean }>`
    display: ${({ rowMode }) => (rowMode ? 'flex' : 'block')};
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
`

export const RowItem = styled.div<{ isOutline?: boolean }>`
    flex-basis: 48%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme, isOutline }) => (isOutline ? theme.colors.white : theme.colors.primary)};
    color: ${({ theme, isOutline }) => (isOutline ? theme.colors.primary : theme.colors.white)};
    padding: 10px 0px;
    border-radius: 4px;
    font-size: 12px;
    transition: 400ms;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
        opacity: 0.8;
    }
`
export const SingleButton = styled.div<{ isOutline?: boolean; rowMode?: boolean; type?: string; color?: string }>`
    width: 100%;
    border: 1px solid
        ${({ color, theme, type }) => (type === 'delete' ? theme.colors.danger : color || theme.colors.primary)};
    background-color: ${({ color, theme, isOutline, type }) =>
        isOutline ? theme.colors.white : type === 'delete' ? theme.colors.danger : color || theme.colors.primary};
    color: ${({ color, theme, isOutline }) => (isOutline ? color || theme.colors.primary : theme.colors.white)};
    padding: 10px 0px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    margin-left: ${({ rowMode }) => (rowMode ? '10px' : '0px')};
    margin-right: ${({ rowMode }) => (rowMode ? '10px' : '0px')};
`

export const TextButton = styled.div`
    text-align: center;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 20px;
    cursor: pointer;
    transition: 400ms;
    &:hover {
        opacity: 0.8;
    }
`
