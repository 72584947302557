import styled from 'styled-components'

export const DashboardPageContainer = styled.div`
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
`

export const PerformanceContent = styled.div`
    background-color: #e8e8e8;
    flex: 1;
    padding: 20px;
`

export const CardRow = styled.div`
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        display: flex;
        justify-content: space-between;
    }
`

export const RowItem = styled.div`
    height: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: 49%;
    }
`

export const HeaderTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`

export const ContainerButtons = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`

export const ButtonOutline = styled.div`
    cursor: pointer;
    color: #fff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #fff;
    background-color: ${({ theme }) => theme.colors.primary};
    &:hover {
        opacity: 0.6;
    }
`
