import styled, { css } from 'styled-components'

export const PerformanceCardContainer = styled.div<{ fitHeight?: boolean }>`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 20px;
    border-radius: 12px;
    width: calc(100% - 40px);
    margin-bottom: 25px;

    ${({ fitHeight }) =>
        !fitHeight &&
        css`
            min-height: 330px;
        `}
`

export const PerformanceTitle = styled.div`
    color: #6a6a6a;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
`

export const PerformanceSubtitle = styled.div`
    font-size: 12px;
    margin-bottom: 40px;
    color: #ababab;
`

export const PerformanceCardContent = styled.div``
