import { forwardRef, useRef, useImperativeHandle, useState, useCallback, memo, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import _ from 'lodash'
import LateralModalBase from 'modals/lateral-modal-base/lateral-modal-base'
import { ModalRouteFinish } from 'modals/modal-route-cancel/modal-route-cancel'

import { EmptyListMessage } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { useUI } from 'contexts'
import { formatAddress, getBikerType, getStatusOrder, showErrors } from 'helpers'
import { getColorFromRoute } from 'helpers/getColorFromRoute'
import { useAuth } from 'hooks'
import api from 'services/api'
import { Route } from 'types'

import { FinishRoutes, ContainerItemRoute, Collect, MapImage, SeeMore, Content } from './modal-routes-problem.styled'

type ModalRoutesProblem = {
    show?(): void
    close?(): void
}

const RouteStatus = {
    Started: 2,
    Finished: 3,
    Cancelled: 9,
}

const ModalRoutesProblem = memo(
    forwardRef<LateralModalBase, any>((_, ref) => {
        const history = useHistory()
        const lateralModalBaseRef = useRef<LateralModalBase | null>(null)
        const modalRouteFinishRef = useRef(null)

        const { basePath, mall } = useAuth()
        const { setErrorModal, setSuccessModal, setConfirmationModal } = useUI()

        const [routes, setRoutes] = useState<Route[]>([])
        const [isExpand, setExpand] = useState(false)
        const [loader, setLoader] = useState(false)

        const _getRoutes = useCallback(async () => {
            setLoader(true)

            try {
                const { data } = await api.get('/painel/routes/waiting', {
                    params: { mall: mall.id, per_page: -1 },
                })

                setRoutes(data.items.filter(x => x.has_problem))
            } catch (error) {
                console.log({ error })
            }

            setLoader(false)
        }, [mall.id])

        useImperativeHandle(
            ref,
            () => ({
                show: () => {
                    lateralModalBaseRef.current?.show()

                    _getRoutes()
                },
                close: () => {
                    lateralModalBaseRef.current?.close()
                },
            }),
            [_getRoutes]
        )

        const _onClose = useCallback(() => {
            setLoader(false)
            setRoutes([])
        }, [])

        const _expand = useCallback(() => {
            setExpand(state => !state)
        }, [])

        const _canCancelRoute = useCallback(route => !route?.orders?.length, [])

        const _canFinishRoute = useCallback(route => {
            return route && ![RouteStatus.Finished, RouteStatus.Cancelled].includes(route.status) && !!route.biker
        }, [])

        const _routeDetailsClick = useCallback(
            (id: number) => {
                return () => history.push(`${basePath}/detalhes-rota/${id}`)
            },
            [basePath, history]
        )

        const _cancelRoute = useCallback(
            routeId => {
                return () => {
                    const callback = async () => {
                        setLoader(true)
                        try {
                            await api.delete(`/painel/route/${routeId}`)

                            setSuccessModal({
                                title: 'Sucesso',
                                subtitle: 'Rota cancelada com sucesso!',
                            })
                            setRoutes(state => {
                                return state.filter(x => x.id !== routeId)
                            })
                        } catch (error) {
                            setErrorModal({
                                title: 'Erro',
                                subtitle: showErrors(error),
                            })
                        }
                        setLoader(false)
                    }

                    setConfirmationModal({
                        title: 'Cancelar Rota',
                        subtitle: 'Deseja realmente cancelar está rota?',
                        rightButtonText: 'Sim',
                        leftButtonText: 'Não',
                        rightButtonClick: callback,
                    })
                }
            },
            [setConfirmationModal, setErrorModal, setSuccessModal]
        )

        const _confirmFinish = useCallback(
            route => {
                const callback = async (route_id, reason) => {
                    setLoader(true)

                    try {
                        await api.post(`/painel/route/finish`, { route_id, reason })

                        setSuccessModal({
                            title: 'Sucesso',
                            subtitle: 'Rota finalizada com sucesso!',
                        })
                        setRoutes(state => {
                            return state.filter(x => x.id !== route_id)
                        })
                    } catch (error) {
                        console.log('error', error)
                        setErrorModal({
                            title: 'Erro',
                            subtitle: showErrors(error),
                        })
                    }
                    setLoader(false)
                }

                modalRouteFinishRef.current?.show({
                    route,
                    onSelectReason: reason => {
                        callback(route.id, reason)
                    },
                })
            },
            [setErrorModal, setSuccessModal]
        )

        return (
            <>
                <LateralModalBase ref={lateralModalBaseRef} title="Rotas com Problema" onClose={_onClose}>
                    {routes.length ? (
                        <Content>
                            <div className="routes-running-table-row">
                                <div className="routes-running-row-column id">ID</div>
                                <div className="routes-running-row-column orders">Pedidos</div>
                                <div className="routes-running-row-column deliveryman">Entregador</div>
                                <div className="routes-running-row-column button" />
                            </div>

                            {routes?.map(route => (
                                <ContainerItemRoute key={route.id} color={getColorFromRoute(route.id)}>
                                    <div className="routes-running-table-row">
                                        <div className="routes-running-row-column id">{route.id}</div>
                                        <div className="routes-running-row-column orders">
                                            {!route.orders.length && (
                                                <div className="danger-info">
                                                    <div className="info-title">Rota Vazia</div>
                                                    <div className="info-message">
                                                        Priorize esse entregador na sua criação de rotas
                                                    </div>
                                                </div>
                                            )}
                                            {route.orders
                                                .slice(0, isExpand ? route.orders.length - 1 : 3)
                                                .map((order, index) => (
                                                    <Fragment key={index}>
                                                        <span className="order-store-item">
                                                            {order.merchant.name}{' '}
                                                            <span className="order-reference-id">{`(#${
                                                                order.reference_id || order.id
                                                            })`}</span>
                                                        </span>
                                                        {!!order?.delivery_origin?.name && (
                                                            <Collect>
                                                                <MapImage />
                                                                <div className="collect">
                                                                    Coleta:{' '}
                                                                    <span className="collect">
                                                                        {' '}
                                                                        {order.delivery_origin.name}
                                                                    </span>
                                                                </div>
                                                            </Collect>
                                                        )}
                                                        <span className="order-info">{order?.customer?.name}</span>
                                                        <span className="order-info">
                                                            {formatAddress(order.address)}
                                                        </span>
                                                        <span className="order-status">
                                                            {getStatusOrder(order.status)}
                                                        </span>
                                                    </Fragment>
                                                ))}
                                            {route.orders.length > 3 && (
                                                <SeeMore onClick={_expand}>
                                                    {isExpand ? 'Ver Menos' : 'Ver Mais'}
                                                </SeeMore>
                                            )}
                                        </div>
                                        <div className="routes-running-row-column deliveryman">
                                            {route.biker ? (
                                                <div className="deliveryman-data">
                                                    <div className="info-route">
                                                        <span className="name">{`${route.biker.first_name} ${route.biker.last_name}`}</span>
                                                        <span className="modal">{getBikerType(route.biker.type)}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                        <div className="routes-running-row-column button">
                                            <div className="button-item outline" onClick={_routeDetailsClick(route.id)}>
                                                Detalhes
                                            </div>
                                            {!_canCancelRoute(route) && _canFinishRoute(route) && (
                                                <FinishRoutes onClick={() => _confirmFinish(route)}>
                                                    Finalizar Rota
                                                </FinishRoutes>
                                            )}

                                            {_canCancelRoute(route) && (
                                                <FinishRoutes onClick={_cancelRoute(route.id)}>
                                                    Cancelar Rota
                                                </FinishRoutes>
                                            )}
                                        </div>
                                    </div>
                                </ContainerItemRoute>
                            ))}
                        </Content>
                    ) : (
                        <EmptyListMessage icon="exclamation-triangle" message="Nenhum resultado encontrado" />
                    )}
                    <ModalLoading visible={loader} />
                </LateralModalBase>
                <ModalRouteFinish ref={modalRouteFinishRef} />
            </>
        )
    })
)

export { ModalRoutesProblem }
