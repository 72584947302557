import React, { memo, useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'

import { AmountCard, LoadingMessage, PerformanceCard } from 'components/_common'

import { api2 } from 'services/api'

import { AmountRow, AmountItem } from './overview-card.styled'

type ReportScoreboard = {
    problems: number
    orders: number
    fails: number
}
interface Props {
    params?: any
}
const OverviewCard: React.FC<Props> = memo(({ params }) => {
    const [data, setData] = useState<ReportScoreboard>()
    const [loading, setLoading] = useState(false)

    const _loadData = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const response = await api2.get<ReportScoreboard>('report/order-problems/scoreboard', { params })

            setData(response.data)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (params) {
            _loadData(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <PerformanceCard title="Visão geral" subtitle="Placar geral" fitHeight>
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!data && (
                        <AmountRow>
                            <AmountItem>
                                <AmountCard number={data.orders || 0} title="Total de Pedidos" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={data.problems || 0} title="Total de Coleta Improdutivas" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={data.fails || 0} title="Total de Falso Positivo" />
                            </AmountItem>
                        </AmountRow>
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { OverviewCard }
