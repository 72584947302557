import React, { memo } from 'react'

import { AmountCard, LoadingMessage, PerformanceCard } from 'components/_common'

import { DashboardOverview } from 'containers/dashboard-page/dashboard-page'

import { formatCurrency } from 'helpers'
import { useOrdersInProgress } from 'hooks/orders'

import { AmountRow, AmountItem } from './overview-card.styled'

interface Props {
    overview?: DashboardOverview
    loading?: boolean
}
const OverviewCard: React.FC<Props> = memo(({ overview, loading }) => {
    const { orders } = useOrdersInProgress()

    return (
        <PerformanceCard title="Pedidos" subtitle="Visão geral da operação" fitHeight>
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!orders && (
                        <AmountRow>
                            <AmountItem>
                                <AmountCard number={orders.scheduled.items.length} title="Agendados" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={orders.inProduction.items.length} title="Em Produção" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={orders.toCollect.items.length} title="Pronto p/ Coleta" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={orders.awaitingCollect.items.length} title="Aguardando Coleta" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={orders.inRoute.items.length} title="Em rota" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={orders.delivered.items.length} title="Entregues" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={orders.canceled.items.length} title="Cancelados" />
                            </AmountItem>
                        </AmountRow>
                    )}
                    {!!overview && (
                        <AmountRow>
                            <AmountItem>
                                <AmountCard number={formatCurrency(overview.ticket_average)} title="Ticket Médio" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={overview.time_average} title="Tempo Médio de Preparo" />
                            </AmountItem>
                            <AmountItem>
                                <AmountCard number={overview.collect_time_average} title="Tempo Médio de Coleta" />
                            </AmountItem>
                        </AmountRow>
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { OverviewCard }
