import { useCallback, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { produce } from 'immer'

import TopTagItem from 'components/top-tags/top-tag-item/top-tag-item'

import {
    getAreaOrdersChart,
    getPaymentOrdersChart,
    getSalesChannelsChart,
    getStoresChart,
} from 'helpers/performance-page'
import { useAgentsAvailable, useAuth } from 'hooks'
import { useOrdersInProgress } from 'hooks/orders'
import { useEchoConnection } from 'hooks/use-echo-connection'
import { api2 } from 'services/api'
import { ChartObject, ChartResponse, ReportScoreboard } from 'types'

import { OrdersByAreaCard, OrdersByStoreCard, OverviewCard, PaymentMethodsCard, SalesChannelsCard } from './cards'
import {
    CardRow,
    PerformanceContent,
    DashboardPageContainer,
    RowItem,
    HeaderTitle,
    HeaderContainer,
    ContainerButtons,
    ButtonOutline,
} from './dashboard-page.styles'

export interface DashboardOrdersScoreboard {
    at_client: number
    canceled: number
    collected: number
    delivered: number
    failed: number
    finished: number
    in_moderation: number
    in_production: number
    in_progress: number
    new: number
    routed: number
    scheduled: number
    to_collect: number
}

export interface DashboardOverview {
    collect_time_average: string
    orders_total: number
    ticket_average: number
    time_average: string
}

type Report = {
    scoreboard?: DashboardOrdersScoreboard
    salesChannels?: ChartObject
    paymentMethods?: ChartObject
    ordersByArea?: ChartObject
    ordersByStore?: ChartObject
}

export default function DashboardPage(): JSX.Element {
    const { loading, reports, countAgent, refresh } = useFetchDashboard()
    return (
        <DashboardPageContainer>
            <HeaderContainer>
                <HeaderTitle>Dashboard</HeaderTitle>

                <ContainerButtons>
                    <TopTagItem
                        iconName="users"
                        value={countAgent}
                        label={countAgent === 1 ? 'Entregador' : 'Entregadores'}
                    />
                    <ButtonOutline onClick={refresh}>
                        <FontAwesomeIcon icon="redo" />
                    </ButtonOutline>
                </ContainerButtons>
            </HeaderContainer>

            <PerformanceContent>
                <CardRow>
                    <OverviewCard loading={loading} />
                </CardRow>
                <CardRow>
                    <RowItem>
                        <SalesChannelsCard loading={loading} chart={reports.salesChannels} />
                    </RowItem>
                    <RowItem>
                        <PaymentMethodsCard loading={loading} chart={reports.paymentMethods} />
                    </RowItem>
                </CardRow>
                <CardRow>
                    <RowItem>
                        <OrdersByAreaCard loading={loading} chart={reports.ordersByArea} />
                    </RowItem>
                    <RowItem>
                        <OrdersByStoreCard loading={loading} chart={reports.ordersByStore} />
                    </RowItem>
                </CardRow>
            </PerformanceContent>
        </DashboardPageContainer>
    )
}

function useFetchDashboard() {
    const { mall } = useAuth()

    const { agents } = useAgentsAvailable()
    const { scoreboard, revalidateOrders } = useOrdersInProgress()

    const [loading, setLoading] = useState(true)

    const [reports, setReports] = useState<Report>({})

    const _loadScoreborad = useCallback(async () => {
        try {
            const { data } = await api2.get<DashboardOrdersScoreboard>(`/orders/dashboard/scoreboard/${mall.id}`)

            setReports(
                produce(state => {
                    state.scoreboard = data
                })
            )
        } catch (error) {
            console.log(error)
        }
    }, [mall.id])

    const _loadSalesChannels = useCallback(async () => {
        try {
            const { data } = await api2.get<ChartResponse>(`/orders/dashboard/channels/${mall.id}`)
            const chart = getSalesChannelsChart(data)
            setReports(
                produce(state => {
                    state.salesChannels = chart
                })
            )
        } catch (error) {
            console.log(error)
        }
    }, [mall.id])

    const _loadPaymentMethods = useCallback(async () => {
        try {
            const { data } = await api2.get<ChartResponse>(`/orders/dashboard/payments/${mall.id}`)
            const chart = getPaymentOrdersChart(data)
            setReports(
                produce(state => {
                    state.paymentMethods = chart
                })
            )
        } catch (error) {
            console.log(error)
        }
    }, [mall.id])

    const _loadOrdersByArea = useCallback(async () => {
        try {
            const { data } = await api2.get<ChartResponse>(`/orders/dashboard/areas/${mall.id}`)
            const chart = getAreaOrdersChart(data)
            setReports(
                produce(state => {
                    state.ordersByArea = chart
                })
            )
        } catch (error) {
            console.log(error)
        }
    }, [mall?.id])

    const _loadOrdersByStore = useCallback(async () => {
        try {
            const { data } = await api2.get<ChartResponse>(`/orders/dashboard/stores/${mall.id}`)
            const chart = getStoresChart(data)
            setReports(
                produce(state => {
                    state.ordersByStore = chart
                })
            )
        } catch (error) {
            console.log(error)
        }
    }, [mall?.id])

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.scoreboard-dashboard',
                callback: (payload: DashboardOrdersScoreboard) => {
                    setReports(
                        produce(state => {
                            state.scoreboard = payload
                        })
                    )
                },
            },
            {
                name: '.area-dashboard',
                callback: (payload: ChartResponse) => {
                    setReports(
                        produce(state => {
                            state.ordersByArea.data = payload
                        })
                    )
                },
            },
            {
                name: '.payments-dashboard',
                callback: (payload: ChartResponse) => {
                    setReports(
                        produce(state => {
                            state.paymentMethods.data = payload
                        })
                    )
                },
            },
            {
                name: '.channels-dashboard',
                callback: (payload: ChartResponse) => {
                    setReports(
                        produce(state => {
                            state.salesChannels.data = payload
                        })
                    )
                },
            },
            {
                name: '.stores-dashboard',
                callback: (payload: ChartResponse) => {
                    setReports(
                        produce(state => {
                            state.ordersByStore.data = payload
                        })
                    )
                },
            },
        ]
    }, [])

    useEchoConnection<any, any>({
        channelName: mall.id ? `v2.mall.${mall.id}` : undefined,
        events: socketEvents,
    })

    const _getData = useCallback(async () => {
        setLoading(true)
        try {
            await Promise.all([
                _loadScoreborad(),
                _loadSalesChannels(),
                _loadPaymentMethods(),
                _loadOrdersByArea(),
                _loadOrdersByStore(),
            ])
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _refresh = useCallback(() => {
        _getData()
        revalidateOrders()
    }, [_getData, revalidateOrders])

    useEffect(() => {
        _getData()
    }, [_getData])

    return { loading, reports, countAgent: agents.items.length, scoreboard, refresh: _refresh }
}
