export const financialContent = [
    {
        icon: 'money-bill',
        label: 'Financeiro',
        route: '/financeiro',
    },
    {
        icon: 'file-invoice',
        label: 'Notas de débito',
        route: '/notas',
    },
]

export const bikersContent = [
    {
        icon: 'home',
        label: 'Dashboard',
        route: '/painel-entregadores',
    },
    {
        icon: 'users',
        label: 'Pré-cadastro',
        route: '/entregadores-pre-cadastro',
    },
    {
        icon: 'list-alt',
        label: 'Cadastros em andamento',
        route: '/entregadores-moderacao/pendentes',
    },
    {
        icon: 'clock',
        label: 'Moderação',
        route: '/entregadores-moderacao',
    },
    {
        icon: 'thumbs-up',
        label: 'Aprovados',
        route: '/entregadores-moderacao/aprovados',
    },
    {
        icon: 'thumbs-down',
        label: 'Reprovados',
        route: '/entregadores-moderacao/reprovados',
    },
    {
        icon: 'ban',
        label: 'Bloqueados',
        route: '/entregadores-moderacao/bloqueados',
    },
]

export const mallsContent = [
    {
        icon: 'store',
        label: 'Malls',
        route: '/painel-malls',
    },
    {
        icon: 'satellite',
        label: 'Visão geral de malls',
        route: '/painel-malls/visao-geral',
    },
    {
        icon: 'clipboard-list',
        label: 'Canais de Vendas',
        route: '/painel-malls/canais-de-vendas',
    },
    {
        icon: 'credit-card',
        label: 'Formas de Pagamento',
        route: '/painel-malls/pagamentos',
    },
    {
        icon: 'chart-line',
        label: 'Performance',
        route: '/painel-malls/performance',
    },
    {
        icon: 'fire',
        label: 'Mapa de calor',
        route: '/painel-malls/heatmap',
    },
    {
        icon: 'user-friends',
        label: 'Gerenciar Administração',
        route: '/painel-malls/administracao',
    },
    {
        icon: 'link',
        label: 'Gerenciar Integrações',
        route: '/painel-malls/integracoes',
    },
    {
        icon: 'user-tie',
        label: 'Gerenciar Lojistas',
        route: '/painel-malls/gerenciar-lojista',
    },
]

export const marketplaceContent = [
    {
        icon: 'store',
        label: 'Gestão Marketplace',
        route: '/marketplace/gestao-marketplace',
    },
    {
        icon: 'money-bill',
        label: 'Vendas',
        route: '/marketplace/vendas',
    },
]

export const storeContent = [
    {
        icon: 'home',
        label: 'Visão Geral',
        route: 'visao-geral',
    },
    {
        icon: 'paper-plane',
        label: 'Novo Pedido',
        route: `visao-geral/em-andamento/solicitar-entrega`,
    },
    {
        icon: 'shopping-bag',
        label: 'Pedidos',
        route: 'pedidos',
    },
    {
        icon: 'chart-line',
        label: 'Performance',
        route: 'performance',
    },
    {
        icon: 'times-circle',
        label: 'Coleta Improdutiva',
        route: 'coleta-improdutiva',
    },
    {
        icon: 'sliders-h',
        label: 'Integrações',
        route: `visao-geral/em-andamento/integracao`,
    },
    {
        icon: 'store',
        label: 'Gestão Marketplace',
        route: 'gestao-marketplace',
    },
    {
        icon: 'file',
        label: 'Importações de Pedidos',
        route: 'importacoes',
    },
]

export const adminContent = [
    {
        icon: 'home',
        label: 'Visão Geral',
        route: 'rotas/visao-geral',
    },
    {
        icon: 'paper-plane',
        label: 'Roteirização',
        route: 'rotas/criar-rota',
    },
    {
        icon: 'map',
        label: 'Rotas',
        route: 'rotas',
    },
    {
        icon: 'users',
        label: 'Entregadores Autorizados',
        route: 'entregadores-autorizados',
    },
    {
        icon: 'shopping-bag',
        label: 'Pedidos',
        route: 'pedidos',
    },
    {
        icon: 'chart-bar',
        label: 'Performance',
        route: 'performance',
    },
    {
        icon: 'tachometer-alt',
        label: 'Dashboard',
        route: 'dashboard',
    },
    {
        icon: 'times-circle',
        label: 'Coleta Improdutiva',
        route: 'coleta-improdutiva',
    },
    {
        icon: 'money-bill',
        label: 'Pagamentos',
        route: 'pagamentos',
    },
    {
        icon: 'cog',
        label: 'Gestão do Mall',
        route: 'gestao-mall',
    },
]
