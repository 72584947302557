import { memo, useEffect } from 'react'

import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputItem } from 'components/_common'
import ModalLoading from 'components/modal-loading'

import { getFormInputError, getFormattedDateFilters } from 'helpers'
import { useAuth, useDataFetching } from 'hooks'
import { Mall, PagedList, PerformanceFilters, Store } from 'types'

import {
    ButtonsContainer,
    HeaderContainer,
    HeaderTitle,
    InputContainer,
    InputsRow,
    MiddleContainer,
    PeriodButton,
    PrimaryButton,
} from './performance-header.styles'

interface Props {
    title?: string
    filters?: PerformanceFilters
    onFilterData?(filters: PerformanceFilters): void
    onSelectStore?(store: Store): void
    submitOnRender?: boolean
}

const PerformanceHeader: React.FC<Props> = memo(({ title, submitOnRender, filters, onFilterData, onSelectStore }) => {
    const { mall, store } = useAuth()

    const { errors, getFieldProps, handleSubmit, touched, setFieldValue, setValues, values } = useFormik({
        initialValues: {
            mall_id: '',
            store_id: '',
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            ...filters,
        },
        validationSchema: Yup.object().shape({
            mall_id: mall?.id ? Yup.string().optional() : Yup.string().required('Selecione o Mall'),
            start_date: Yup.string().required('Selecione a Data inicial'),
            end_date: Yup.string().required('Selecione a Data final'),
        }),
        onSubmit: async values => {
            if (onFilterData) {
                onFilterData({
                    ...values,
                    mall_id: mall?.id ? undefined : values.mall_id ? Number(values.mall_id) : undefined,
                    store_id: store?.id ? undefined : values.store_id ? Number(values.store_id) : undefined,
                    start_date: format(values.start_date, 'YYYY-MM-DD'),
                    end_date: format(values.end_date, 'YYYY-MM-DD'),
                })
            }
        },
    })

    const { data: malls, loading: loadingMalls } = useDataFetching<PagedList<Mall>>(mall?.id ? null : '/painel/malls', {
        params: { order_by: 'name', status: [1], per_page: 100 },
    })
    const { data: stores, loading: loadingStores } = useDataFetching<PagedList<Store>>(
        store?.id ? null : '/painel/stores-to-select',
        {
            params: { mall_id: values.mall_id || mall.id },
        }
    )

    const periodFilters = getFormattedDateFilters<typeof values>(values, setValues)

    useEffect(() => {
        if (submitOnRender) {
            setFieldValue('start_date', format(new Date(), 'YYYY-MM-DD'))
            setFieldValue('end_date', format(new Date(), 'YYYY-MM-DD'))
            setFieldValue('selectedPeriod', 'today')

            setTimeout(() => {
                handleSubmit()
            }, 500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitOnRender])

    return (
        <>
            <ModalLoading visible={loadingMalls || loadingStores} />
            <HeaderContainer>
                <HeaderTitle>{title}</HeaderTitle>
                <MiddleContainer>
                    <InputsRow>
                        {!mall?.id && (
                            <InputContainer>
                                <InputItem
                                    labelText="Mall"
                                    type="select"
                                    options={malls?.items.map(mall => ({
                                        label: mall.name,
                                        value: mall.id,
                                    }))}
                                    inputProps={{
                                        ...getFieldProps('mall_id'),
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('mall_id', value)
                                            setFieldValue('store_id', '')
                                        },
                                    }}
                                    errorMessage={getFormInputError('mall_id', errors, touched)}
                                />
                            </InputContainer>
                        )}
                        {!store?.id && (
                            <InputContainer>
                                <InputItem
                                    labelText="Loja"
                                    type="select"
                                    options={stores?.items.map(store => ({
                                        label: store.name,
                                        value: store.id,
                                    }))}
                                    inputProps={{
                                        ...getFieldProps('store_id'),
                                        onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue('store_id', value)
                                            if (onSelectStore) {
                                                onSelectStore(stores.items.find(item => item.id === Number(value)))
                                            }
                                        },
                                    }}
                                />
                            </InputContainer>
                        )}
                        <InputContainer>
                            <InputItem
                                labelText="Data inicial"
                                type="date"
                                inputProps={getFieldProps('start_date')}
                                errorMessage={getFormInputError('start_date', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Data final"
                                type="date"
                                inputProps={getFieldProps('end_date')}
                                errorMessage={getFormInputError('end_date', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Hora inicial"
                                type="time"
                                inputProps={getFieldProps('start_time')}
                                errorMessage={getFormInputError('start_time', errors, touched)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputItem
                                labelText="Hora final"
                                type="time"
                                inputProps={getFieldProps('end_time')}
                                errorMessage={getFormInputError('end_time', errors, touched)}
                            />
                        </InputContainer>
                        <PrimaryButton onClick={() => handleSubmit()}>Consultar</PrimaryButton>
                    </InputsRow>
                    <ButtonsContainer>
                        {periodFilters.map((filter, filterIndex) => (
                            <PeriodButton key={filterIndex} onClick={filter.onClick}>
                                {filter.label}
                            </PeriodButton>
                        ))}
                    </ButtonsContainer>
                </MiddleContainer>
            </HeaderContainer>
        </>
    )
})

export default PerformanceHeader
