import styled from 'styled-components'

const Contaienr = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    padding: 20px;
    display: flex;
    align-items: center;
`

const ContainerAvatar = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.colors.primary};
    margin-right: 20px;
    position: relative;
`

const Avatar = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`

const ContainerInfo = styled.div`
    flex: 1;
`

const RiderName = styled.div`
    font-size: 14px;
`

const RiderPhone = styled.div`
    font-weight: bold;
    font-size: 12px;
    color: #999;
`

const ContainerOrders = styled.div`
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    display: block;
`

const OrderItem = styled.div`
    border-radius: 20px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 5px;
    position: relative;
    color: ${({ theme }) => theme.colors.primary};
    transition: 400ms;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .merchant {
        text-transform: none;
        color: ${({ theme }) => theme.colors.mediumGray};
        font-weight: bold;
        font-size: 12px;
    }
    .status {
        text-transform: none;
        color: ${({ theme }) => theme.colors.darkGray};
        font-size: 12px;
        font-weight: normal;
    }
    .identificator {
        color: ${({ theme }) => theme.colors.darkGray};
        font-weight: normal;
    }
    .info-order {
        color: ${({ theme }) => theme.colors.black};
        font-size: 9px;
        text-transform: uppercase;
    }
`

const DetailsButton = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    padding: 3px 10px;
    border-radius: 12px;
    margin-left: 30px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    transition: 400ms;
    &:hover {
        opacity: 0.7;
    }
`

export {
    Contaienr,
    ContainerAvatar,
    Avatar,
    OrderItem,
    ContainerInfo,
    RiderName,
    RiderPhone,
    ContainerOrders,
    DetailsButton,
}
