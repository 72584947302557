import { createReducer, createActions } from 'reduxsauce'

import { getOrderWithTimes } from 'helpers'
import { Order } from 'types'

import { findOrderListKey } from './helpers'
import {
    ActionCreators,
    ActionTypes,
    Actions,
    AddOrderAction,
    RemoveOrderAction,
    SetOrdersAction,
    State,
    UpdateOrderAction,
    SetListLoadingAction,
    SetWebSocketConnectionAction,
    SetHighlightedOrderIdAction,
    SetScoreboardAction,
    SetRouteOrdersAction,
    ClearOrderAction,
} from './types'

/* Types & Action Creators */

export const { Types: OrdersInProgressTypes, Creators: OrdersInProgressActions } = createActions<
    ActionTypes,
    ActionCreators
>(
    {
        setHighlightedOrderId: ['orderId'],
        setScoreboard: ['scoreboard'],
        setRouteOrders: ['routeOrders'],
        setWebSocketConnection: ['connection', 'mall', 'store'],
        setListLoading: ['list', 'loading'],
        setOrders: ['list', 'orders'],
        addOrder: ['order'],
        updateOrder: ['order'],
        removeOrder: ['order'],
        clearOrder: [],
    },
    { prefix: 'ordersInProgress/' }
)

/* Initial State */

const initialState: State = {
    highlightedOrderId: undefined,
    webSocketConnection: 'disconnected',
    scoreboard: {
        canceled: 0,
        collected: 0,
        delivered: 0,
        failed: 0,
        finished: 0,
        in_client: 0,
        in_moderation: 0,
        in_production: 0,
        new: 0,
        no_delivery: 0,
        in_progress: 0,
        scheduled: 0,
        at_client: 0,
        routed: 0,
        takeout: 0,
        to_collect: 0,
    },
    routeOrders: [],
    orders: {
        scheduled: { loading: false, totals: 0, items: [] },
        inModeration: { loading: false, totals: 0, items: [] },
        inProduction: { loading: false, totals: 0, items: [] },
        toCollect: { loading: false, totals: 0, items: [] },
        awaitingCollect: { loading: false, totals: 0, items: [] },
        inRoute: { loading: false, totals: 0, items: [] },
        delivered: { loading: false, totals: 0, items: [] },
        canceled: { loading: false, totals: 0, items: [] },
    },
}

/* Reducers */

function setHighlightedOrderId(state = initialState, action: SetHighlightedOrderIdAction): State {
    return {
        ...state,
        highlightedOrderId: action.orderId,
    }
}

function setWebSocketConnection(state = initialState, action: SetWebSocketConnectionAction): State {
    return {
        ...state,
        webSocketConnection: action.connection,
    }
}

function setScoreboard(state = initialState, action: SetScoreboardAction): State {
    return {
        ...state,
        scoreboard: action.scoreboard,
    }
}

function setRouteOrders(state = initialState, action: SetRouteOrdersAction): State {
    return {
        ...state,
        routeOrders: action.routeOrders,
    }
}

function setListLoading(state = initialState, action: SetListLoadingAction): State {
    return {
        ...state,
        orders: {
            ...state.orders,
            [action.list]: {
                ...state.orders[action.list],
                loading: action.loading,
            },
        },
    }
}

function setOrders(state = initialState, action: SetOrdersAction): State {
    return {
        ...state,
        orders: {
            ...state.orders,
            [action.list]: {
                ...action.orders,
                items: action.orders.items.map(order => getOrderWithTimes(order)),
            },
        },
    }
}

function addOrder(state = initialState, action: AddOrderAction): State {
    const listKeyToAdd = findOrderListKey(action.order)
    if (!listKeyToAdd) return state

    const ordersFromList = state.orders[listKeyToAdd].items

    return {
        ...state,
        orders: {
            ...state.orders,
            [listKeyToAdd]: {
                ...state.orders[listKeyToAdd],
                totals: Number(state.orders[listKeyToAdd]?.totals) + 1,
                items: [
                    ...ordersFromList.filter(order => order.id !== action.order.id),
                    getOrderWithTimes(action.order),
                ],
            },
        },
    }
}

function updateOrder(state = initialState, action: UpdateOrderAction): State {
    const { scheduled, inProduction, toCollect, awaitingCollect, inRoute, delivered } = state.orders
    const ordersListKeys = [scheduled, inProduction, toCollect, awaitingCollect, inRoute, delivered]
    const orders: Order[] = ordersListKeys.flatMap(list => list.items)

    const listKeyToAdd = findOrderListKey(action.order)
    if (!listKeyToAdd) return state

    const ordersFromAddList = state.orders[listKeyToAdd].items

    const orderToRemove = orders.find(order => order.id === action.order.id)
    if (!orderToRemove) return state
    const listKeyToRemove = findOrderListKey(orderToRemove)
    const ordersFromRemoveList = listKeyToRemove ? state.orders[listKeyToRemove].items : undefined

    const isOrderInTheSameListKey = listKeyToAdd === listKeyToRemove

    if (ordersFromRemoveList && listKeyToRemove && !isOrderInTheSameListKey) {
        return {
            ...state,
            orders: {
                ...state.orders,
                [listKeyToAdd]: {
                    ...state.orders[listKeyToAdd],
                    totals: Number(state.orders[listKeyToAdd]?.totals) + 1,
                    items: [
                        ...ordersFromAddList.filter(order => order.id !== action.order.id),
                        getOrderWithTimes(action.order),
                    ],
                },
                [listKeyToRemove]: {
                    ...state.orders[listKeyToRemove],
                    totals: Number(state.orders[listKeyToRemove]?.totals) - 1,
                    items: ordersFromRemoveList.filter(order => order.id !== action.order.id),
                },
            },
        }
    } else {
        return {
            ...state,
            orders: {
                ...state.orders,
                [listKeyToAdd]: {
                    ...state.orders[listKeyToAdd],
                    items: [
                        ...ordersFromAddList.filter(order => order.id !== action.order.id),
                        getOrderWithTimes(action.order),
                    ],
                },
            },
        }
    }
}

function removeOrder(state = initialState, action: RemoveOrderAction): State {
    const { scheduled, inProduction, toCollect, awaitingCollect, inRoute, delivered } = state.orders
    const ordersListKeys = [scheduled, inProduction, toCollect, awaitingCollect, inRoute, delivered]
    const orders: Order[] = ordersListKeys.flatMap(list => list.items)

    const orderToRemove = orders.find(order => order.id === action.order.id)
    if (!orderToRemove) return state

    const listKeyToRemove = findOrderListKey(orderToRemove)
    if (!listKeyToRemove) return state

    const ordersFromRemoveList = state.orders[listKeyToRemove].items

    return {
        ...state,
        orders: {
            ...state.orders,
            [listKeyToRemove]: {
                ...state.orders[listKeyToRemove],
                totals: Number(state.orders[listKeyToRemove]?.totals) - 1,
                items: ordersFromRemoveList.filter(order => order.id !== action.order.id),
            },
        },
    }
}

function clearOrder(state = initialState, action: ClearOrderAction): State {
    return initialState
}

/* Reducers to types */

export const ordersInProgressReducer = createReducer<State, Actions>(initialState, {
    [OrdersInProgressTypes.SET_HIGHLIGHTED_ORDER_ID]: setHighlightedOrderId,
    [OrdersInProgressTypes.SET_WEB_SOCKET_CONNECTION]: setWebSocketConnection,
    [OrdersInProgressTypes.SET_SCOREBOARD]: setScoreboard,
    [OrdersInProgressTypes.SET_ROUTE_ORDERS]: setRouteOrders,
    [OrdersInProgressTypes.SET_LIST_LOADING]: setListLoading,
    [OrdersInProgressTypes.SET_ORDERS]: setOrders,
    [OrdersInProgressTypes.ADD_ORDER]: addOrder,
    [OrdersInProgressTypes.UPDATE_ORDER]: updateOrder,
    [OrdersInProgressTypes.REMOVE_ORDER]: removeOrder,
    [OrdersInProgressTypes.CLEAR_ORDER]: clearOrder,
})
