import React, { memo, useCallback, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import { LoadingMessage, PerformanceCard } from 'components/_common'

import { getChartLabel } from 'helpers/performance-page'
import { api2 } from 'services/api'
import { ChartObject, ReportPaymentForms } from 'types'

interface Props {
    params: any
}

const AgentsCard: React.FC<Props> = memo(({ params }) => {
    const [chart, setChart] = useState<ChartObject>()
    const [loading, setLoading] = useState(false)

    const _loadData = useCallback(async (params: any) => {
        setLoading(true)
        try {
            const response = await api2.get<ReportPaymentForms>('report/order-problems/bikers', { params })
            const chart = getChartLabel(response.data)
            setChart(chart)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (params) {
            _loadData(params)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <PerformanceCard title="Por Entregador" subtitle="Coletas improdutivas por Entregador">
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!chart && (
                        <Bar
                            data={chart.data}
                            height={400}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    tooltip: {
                                        callbacks: chart.callbacks,
                                    },
                                },
                            }}
                        />
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { AgentsCard }
