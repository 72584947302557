import React, { useMemo, useCallback, useEffect, useState, useReducer } from 'react'

import { getTimeDiff } from 'helpers'
import { useAuth } from 'hooks'
import { useEchoConnection } from 'hooks/use-echo-connection'
import api, { echoConnection } from 'services/api'
export const RoutesContext = React.createContext({ loading: false, routes: [], allRoutes: [], refresh: () => {} })

const echo = echoConnection()

const listReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return action.data.map(item => action.transform(item))
        case 'add':
            return [...state.filter(item => item.id !== action.item.id), action.transform(action.item)]
        case 'update':
            return state.map(item => (item.id === action.id ? { ...item, ...action.transform(action.item) } : item))
        case 'update-agent-location':
            return state.map(item =>
                item.biker?.id === action.agent.id ? { ...item, biker: { ...item.biker, ...action.agent } } : item
            )
        case 'update-route-order':
            return state.map(item =>
                item.orders.some(order => order.id === action.order.id)
                    ? {
                          ...item,
                          orders: item.orders.map(order =>
                              order.id === action.order.id ? { order, ...action.order } : order
                          ),
                      }
                    : item
            )
        case 'remove':
            return state.filter(item => item.id !== action.id)

        default:
            throw new Error('Unexpected action')
    }
}

export function ContainerRoutesContext({ children, onRoutesChanges, onAgentsChanges, tabActive }) {
    const { mall } = useAuth()

    const [bikersSocketConnected, setBikersSocketConnected] = useState(false)
    const [bikers, dispatchBikers] = useReducer(listReducer, [])
    const [routes, dispatchRoutes] = useReducer(listReducer, [])
    const [loading, setLoading] = useState(true)

    const _getRoutes = useCallback(async () => {
        let routes = []
        setLoading(true)

        try {
            const { data } = await api.get('/painel/routes/waiting', {
                params: { mall: mall.id, per_page: -1 },
            })
            routes = data.items
        } catch (error) {
            console.log({ error })
        }

        try {
            const { data } = await api.get('/painel/routes/running', {
                params: { mall: mall.id, per_page: -1 },
            })
            routes = [...routes, ...data.items]
        } catch (error) {
            console.log({ error })
        }

        dispatchRoutes({ type: 'set', data: routes, transform: getRouteWithTotalTime })

        setLoading(false)
    }, [mall.id])

    const _listenToBikersChannel = useCallback(() => {
        try {
            if (!bikersSocketConnected) {
                echo.join(`bikers.mall.${mall.id}`)

                    .here(users => {
                        console.log(`bikers.mall.${mall.id} - here`, { users })

                        dispatchBikers({
                            type: 'set',
                            data: users.filter(user => user.role === 'biker'),
                            transform: item => item,
                        })
                    })
                    .joining(user => {
                        console.log(`bikers.mall.${mall.id} - joining`, { user })

                        if (user.role === 'biker') {
                            dispatchBikers({ type: 'add', item: user, transform: item => item })
                        }
                    })
                    .leaving(biker => {
                        console.log(`bikers.mall.${mall.id} - leaving`, { biker })
                        dispatchBikers({ type: 'remove', id: biker.id, transform: item => item })
                    })

                setBikersSocketConnected(true)
            }
        } catch (error) {
            console.log({ error })
        }
    }, [bikersSocketConnected, mall.id])

    useRoutesFetch({
        mallID: mall.id,
        dispatchRoutes,
    })

    useEffect(() => {
        dispatchBikers({ type: 'set', data: [] })
        dispatchRoutes({ type: 'set', data: [] })
        _listenToBikersChannel()
        _getRoutes()

        return function cleanup() {
            echo.leave(`bikers.mall.${mall.id}`)
            console.log(`echo.leave(bikers.mall.${mall.id})`)
        }
        // eslint-disable-next-line
    }, [mall.id])

    const routesFiltered = useMemo(() => {
        return routes.filter(route => {
            if (tabActive === 'routes-no-starting') {
                return [0, 1].includes(Number(route.status))
            }
            if (tabActive === 'routes-in-progress') {
                return Number(route.status) === 2
            }
        })
    }, [routes, tabActive])

    useEffect(() => {
        if (onAgentsChanges) {
            onAgentsChanges(bikers)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bikers])

    useEffect(() => {
        if (routesFiltered) {
            if (onRoutesChanges) {
                onRoutesChanges(routesFiltered)
            }
            dispatchBikers({
                type: 'set',
                data: routesFiltered.map(item => item.biker),
                transform: item => item,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routesFiltered])

    return (
        <RoutesContext.Provider
            value={{ loading: loading, routes: routesFiltered, allRoutes: routes, refresh: _getRoutes }}
        >
            {children}
        </RoutesContext.Provider>
    )
}

function useRoutesFetch({ mallID, dispatchRoutes }) {
    const socketEvents = useMemo(() => {
        return [
            {
                name: '.location',
                callback: agent => {
                    dispatchRoutes({ type: 'update-agent-location', agent })
                },
            },
            {
                name: '.new',
                callback: route => {
                    if ([0, 1, 2].includes(route.status)) {
                        dispatchRoutes({ type: 'add', item: route, transform: getRouteWithTotalTime })
                    }
                },
            },
            {
                name: '.updated',
                callback: route => {
                    if ([0, 1, 2].includes(route.status)) {
                        dispatchRoutes({ type: 'update', id: route.id, item: route, transform: getRouteWithTotalTime })
                    } else {
                        dispatchRoutes({ type: 'remove', id: route.id, item: route, transform: getRouteWithTotalTime })
                    }
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEchoConnection({
        enable: !!mallID,
        channelName: mallID ? `route.mall.${mallID}` : null,
        events: socketEvents,
    })
}

function getRouteWithTotalTime(route) {
    if (!route?.created_at) {
        return route
    }
    return {
        ...route,
        total_time: getTimeDiff(route.created_at),
    }
}
