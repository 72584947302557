import { useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUI } from 'contexts'
import { format } from 'date-fns'

import { TagProblem } from 'components/tag-problem/tag-problem'

import { formatDistance } from 'helpers'
import api from 'services/api'

import {
    Collect,
    RejectButton,
    AcceptButton,
    Container,
    ContainerButtons,
    DetailIcon,
    MapImage,
} from './order-row.styled'

const OrderRow = ({
    orderId = undefined,
    observation = undefined,
    clicked = undefined,
    status = undefined,
    statusColor = undefined,
    orderNumber = undefined,
    orderValue = undefined,
    salesChannel = undefined,
    paymentName = undefined,
    restaurantName = undefined,
    collectName = undefined,
    deliveryForecast = undefined,
    detailClick = undefined,
    routeClick = undefined,
    clientName = undefined,
    isReadyToDeliver = undefined,
    timeReady = undefined,
    neighborhood = undefined,
    street = undefined,
    totalTime = undefined,
    number = undefined,
    distance = undefined,
    hasProblem,
    problems,
}) => {
    const { setConfirmationModal, setErrorModal, setLoading, setSnackbar } = useUI()

    const _changesOrderStatus = useCallback(
        async (orderId, status, successMessage) => {
            try {
                setLoading(true)

                switch (status) {
                    case 0:
                        await api.put(`/painel/dm-order/${orderId}/cancel`)
                        break
                    case 3:
                        await api.put(`/painel/dm-order/${orderId}/in_production`)
                        break
                    case 4:
                        await api.put(`/painel/dm-order/${orderId}/ready_to_delivery`)
                        break
                    default:
                        await api.put(`/painel/dm-order/${orderId}`, { status })
                        break
                }

                setSnackbar({ message: successMessage })
            } catch (error) {
                setErrorModal({
                    title: 'Erro',
                    subtitle: 'Houve um problema ao alterar o status do pedido',
                })
            } finally {
                setLoading(false)
            }
        },
        [setErrorModal, setLoading, setSnackbar]
    )

    const _changeStatus = useCallback(() => {
        const commonProps = {
            type: 'alert',
            modalIcon: 'check-circle',
            leftButtonText: 'Fechar',
            // children: <OrderSummary order={order} />,
        }

        if (status === '3') {
            setConfirmationModal({
                ...commonProps,
                title: 'Disponibilizar pedido p/ coleta?',
                subtitle: 'O pedido ficará disponível para entrega.',
                rightButtonText: 'Disponibilizar',
                rightButtonClick: () => _changesOrderStatus(orderId, 4, 'Pedido pronto para coleta'),
            })
        }

        if (status === '4') {
            setConfirmationModal({
                ...commonProps,
                title: 'Voltar para produção?',
                subtitle: 'O pedido irá voltar para em produção.',

                rightButtonText: 'Confirmar',
                rightButtonClick: () => _changesOrderStatus(orderId, 3, 'Pedido retornado para produção'),
            })
        }
    }, [status, orderId, _changesOrderStatus, setConfirmationModal])

    return (
        <Container
            className="order-row"
            style={{
                backgroundColor: clicked ? 'rgba(255, 232, 162, 0.4)' : '',
                borderLeftColor: statusColor,
            }}
        >
            <div className="order-info">
                <div className="small-text ">
                    <span className="primary">#{orderNumber}</span> • R$ {orderValue} • {salesChannel}
                </div>
                <div className="small-text">{paymentName}</div>
                <div className="black-text name">
                    {restaurantName}
                    {!!observation && (
                        <span className="icon">
                            <FontAwesomeIcon className="icon" icon="exclamation-triangle" size={15} />
                        </span>
                    )}
                </div>
                {!!collectName && (
                    <Collect>
                        <MapImage />
                        <div className="collect">
                            Coleta: <span className="collect"> {collectName}</span>
                        </div>
                    </Collect>
                )}
                {deliveryForecast && (
                    <div className="small-text delivery-forecast">
                        Previsão de entrega: <br />
                        {`${format(deliveryForecast, 'DD/MM/YYYY')} às ${format(deliveryForecast, 'HH:mm')}`}
                    </div>
                )}
                <TagProblem hasProblem={hasProblem} problems={problems} />
            </div>

            <div className="client-info">
                <div className="small-text">{clientName}</div>
                <div className="black-text address">
                    {neighborhood} <br /> {street}, {number}
                    <div className="small-text">{formatDistance(distance)}</div>
                </div>
            </div>

            <div className="time-info">
                <div className="timers">
                    {totalTime && (
                        <div className="black-text total">
                            <FontAwesomeIcon icon="check-circle" color="#aaa" /> {totalTime} total
                        </div>
                    )}
                    {isReadyToDeliver && (
                        <div className="black-text total">
                            <FontAwesomeIcon icon="cube" color="#aaa" /> {timeReady} pronto
                        </div>
                    )}
                </div>
            </div>

            <ContainerButtons>
                {status === '3' && <AcceptButton onClick={_changeStatus}>Pronto</AcceptButton>}
                {isReadyToDeliver && !hasProblem && (
                    <AcceptButton orange onClick={routeClick}>
                        + Rota
                    </AcceptButton>
                )}
                <RejectButton onClick={detailClick}>
                    <span>
                        <DetailIcon />
                    </span>
                    Ver pedido
                </RejectButton>
                {status === '4' && (
                    <RejectButton onClick={_changeStatus}>
                        <span>
                            <FontAwesomeIcon icon="arrow-circle-left" />
                        </span>
                        Voltar pedido
                    </RejectButton>
                )}
            </ContainerButtons>
        </Container>
    )
}

export default OrderRow
