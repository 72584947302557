import React, { memo } from 'react'

import classNames from 'classnames'

import { ILogarooIcons } from 'types/logaroo-icons'

import { IconContainer } from './logaroo-icon.styled'

type Props = {
    className?: string
    icon: ILogarooIcons
    onClick?: () => void
}

const LogarooIcon: React.FC<Props> = memo(({ className, icon, ...props }) => {
    return <IconContainer className={classNames('lg-icon', className, icon)} {...props} />
})

export { LogarooIcon }
