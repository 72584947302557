import styled from 'styled-components'

const AmountRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`

const AmountItem = styled.div`
    margin-bottom: 20px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        width: calc((100% / 3) - 10px);
    }
`

export { AmountRow, AmountItem }
