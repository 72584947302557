import React, { memo } from 'react'
import { Bar } from 'react-chartjs-2'

import { LoadingMessage, PerformanceCard } from 'components/_common'

import { ChartObject } from 'types'

interface Props {
    chart?: ChartObject
    loading?: boolean
}

const OrdersByAreaCard: React.FC<Props> = memo(({ chart, loading }) => {
    return (
        <PerformanceCard
            title="Quantitativo de pedidos por área de cobrança"
            subtitle="Baseado nos pedidos recebidos de cada área de cobrança"
        >
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!chart && (
                        <Bar
                            data={chart.data}
                            width={100}
                            height={30}
                            options={{
                                plugins: {
                                    tooltip: {
                                        callbacks: chart.callbacks,
                                    },
                                },
                            }}
                        />
                    )}
                </>
            )}
        </PerformanceCard>
    )
})

export { OrdersByAreaCard }
