import React, { memo } from 'react'
import { Doughnut } from 'react-chartjs-2'

import { LoadingMessage, PerformanceCard } from 'components/_common'

import { ChartObject } from 'types'

interface Props {
    chart?: ChartObject
    loading?: boolean
}

const SalesChannelsCard: React.FC<Props> = memo(({ chart, loading }) => {
    return (
        <PerformanceCard title="Canais de venda" subtitle="Baseado na quantidade de pedido em cada canal">
            {loading ? (
                <LoadingMessage />
            ) : (
                <>
                    {!!chart && (
                        <Doughnut
                            data={chart.data}
                            height={400}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        position: 'right',
                                    },
                                    tooltip: {
                                        callbacks: chart.callbacks,
                                    },
                                },
                            }}
                        />
                    )}
                </>
            )}
        </PerformanceCard>
    )
})
export { SalesChannelsCard }
