import { memo, useCallback, useMemo } from 'react'

import { LogarooIcon } from 'components/logaroo-icon/logaroo-icon'
import { Text } from 'components/text/text'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth } from 'hooks'
import { api2 } from 'services/api'
import { Order } from 'types'

import { ContainerProblem } from './tag-problem.styled'

type Props = {
    hasProblem?: boolean | null
    problems?: Order['problems']
}

const TagProblem: React.FC<Props> = memo(({ hasProblem, problems }) => {
    const { user } = useAuth()
    const { setConfirmationModal, setErrorModal } = useUI()

    const problem = useMemo(() => {
        return problems?.find(x => x.status)
    }, [problems])

    const props = useMemo(() => {
        const props = {
            text: 'Problema Resolvido',
            icon: 'check-order',
            color: '#fff',
            backgroundColor: '#6BA87C',
        }

        if (problem) {
            props.backgroundColor = '#FFB4B9'
            props.color = '#B3050D'
            props.text = 'Problema de Coleta'
            props.icon = 'cancel-order'
        }
        if (problems) {
            const last = problems[(problems?.length || 1) - 1]
            if (last?.canceled_at) {
                props.backgroundColor = '#b4baff'
                props.color = '#060db3'
                props.text = 'Falso Positivo'
                props.icon = 'cancel-order'
            }
        }

        return props
    }, [problem, problems])

    const _report = useCallback(() => {
        if (!problem) {
            return
        }
        const solveProblem = async () => {
            try {
                await api2.put(`/order-problems/solve/${problem.id}`)
            } catch (error) {
                setErrorModal({
                    subtitle: showErrors(error),
                })
            }
        }
        const cancelProblem = async () => {
            try {
                await api2.put(`/order-problems/cancel/${problem.id}`)
            } catch (error) {
                setErrorModal({
                    subtitle: showErrors(error),
                })
            }
        }

        if (user.isShopkeeper) {
            setConfirmationModal({
                title: 'Atenção',
                subtitle: 'Tem certeza que deseja alterar o status de resolução do problema de coleta?',
                type: 'alert',
                leftButtonText: 'Cancelar',
                rightButtonText: 'Confirmar',
                rightButtonClick: solveProblem,
            })
            return
        }

        setConfirmationModal({
            title: 'Revisão de coleta improdutiva',
            rowMode: false,
            hasOutlineButton: false,
            leftButtonText: 'Problema Resolvido',
            leftButtonColor: '#02B42E',
            leftButtonClick: solveProblem,
            rightButtonText: 'Falso Positivo',
            rightButtonColor: '#3499DB',
            rightButtonClick: cancelProblem,
        })
    }, [problem, user, setConfirmationModal, setErrorModal])

    if (hasProblem === null) {
        return null
    }

    return (
        <ContainerProblem color={props.color} backgroundColor={props.backgroundColor} onClick={_report}>
            <LogarooIcon icon={props.icon as any} />
            <Text text={props.text} />
        </ContainerProblem>
    )
})

export { TagProblem }
