import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import CheckboxInput from 'components/checkbox-input'
import MensagemErro from 'components/mensagem-erro'
import ModalLoading from 'components/modal-loading'

import { formatCurrency, groupItemsByKey, showErrors } from 'helpers'
import { useAuth } from 'hooks'
import api from 'services/api'
import { PagedList, Product } from 'types'

import './style.scss'
import { LateralModalBase } from 'modals'

import {
    ButtonRow,
    ConfirmButton,
    ContentContainer,
    FormSection,
    InputContainer,
    OutsideContainer,
    ProductDescription,
    ProductInfo,
    ProductPhoto,
    ProductRow,
    ProductText,
    Row,
    TextButton,
} from './modal-products-showcase.styled'

import { useUI } from 'contexts'

export interface ModalProductsShowcaseProps {
    isActive?: boolean
    onClose?(): void
    onUpdateProducts(products: Product[]): void
    width?: string
    isExpress?: boolean
}

export interface ModalProductsShowcaseRef {
    openModal(showcaseId: number, selectedProducts?: number[]): void
    closeModal(): void
}

const ModalProductsShowcase: React.ForwardRefRenderFunction<ModalProductsShowcaseRef, ModalProductsShowcaseProps> = (
    { isActive, onClose, onUpdateProducts },
    ref
) => {
    const { store } = useAuth()
    const { setErrorModal, setSuccessModal } = useUI()

    const lateralModalBaseRef = useRef<LateralModalBase>()

    const [products, setProducts] = useState<Product[]>()

    const { initialValues, isSubmitting, values, touched, errors, handleSubmit, resetForm, setFieldValue, setValues } =
        useFormik({
            initialValues: {
                id: null,
                name: '',
                order: 1,
                product_category_id: '',
                products: [] as number[],
            },
            validationSchema: Yup.object().shape({
                products: Yup.array().min(1, 'Selecione pelo menos um produto'),
            }),
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true)

                    const productsSelected = products?.filter(product => values.products.includes(product.id))

                    if (!productsSelected) {
                        return
                    }

                    onUpdateProducts(productsSelected)

                    setSuccessModal({
                        title: 'Sucesso',
                        subtitle: 'Produtos do agrupamento alterados com sucesso',
                        singleButtonClick() {
                            closeModal()
                        },
                    })

                    resetForm()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro',
                        subtitle: showErrors(error),
                    })
                } finally {
                    setSubmitting(false)
                }
            },
        })

    useImperativeHandle(
        ref,
        () => ({
            openModal: (showcaseId: number, selectedProducts: number[]) => {
                if (selectedProducts) {
                    setValues({
                        ...values,
                        id: showcaseId,
                        products: products
                            ?.filter(product => selectedProducts.includes(product.id))
                            .map(product => product.id) as number[],
                    })
                } else {
                    setValues({ ...initialValues })
                }
                lateralModalBaseRef.current?.show()
                //setActive(true)
            },
            closeModal,
        }),
        [products, values]
    )

    const groupedProducts = useMemo(
        () =>
            groupItemsByKey(products, 'category_name', (item: Product) => ({
                ...item,
                category_name: item.category?.name,
            })).sort((a, b) => a.title.localeCompare(b.title)),
        [products]
    )

    const modalTitle = useMemo(() => {
        return `${values.id ? 'Editar' : 'Novo'} agrupamento de vitrine`
    }, [values])

    const onRequestError = useCallback((error: AxiosError) => {
        setErrorModal({
            title: 'Erro',
            subtitle: showErrors(error),
        })
    }, [])

    const _getProducts = useCallback(async () => {
        try {
            const { data } = await api.get<PagedList<Product>>('/painel/products', {
                params: { store_id: store.id, per_page: -1 },
            })
            setProducts(data.items)
        } catch (error) {
            onRequestError(error)
        }
    }, [store])

    const closeModal = useCallback(() => {
        if (onClose) {
            onClose()
        }

        lateralModalBaseRef.current?.close()
    }, [onClose])

    const onChangeProduct = useCallback(
        (productId: number) => {
            if (values.products?.some(id => id === productId)) {
                setFieldValue(
                    'products',
                    values.products?.filter(id => id !== productId)
                )
            } else {
                setFieldValue('products', values.products ? [...values.products, productId] : [productId])
            }
        },
        [values]
    )

    useEffect(() => {
        _getProducts()
    }, [_getProducts])

    return (
        <>
            <LateralModalBase ref={lateralModalBaseRef} title={modalTitle}>
                <ModalLoading visible={isSubmitting} />
                <OutsideContainer>
                    <ContentContainer>
                        <FormSection>
                            <InputContainer>
                                <div className="products-checkbox-container">
                                    <label className="products-label">Selecione os produtos para adicionar</label>

                                    <div className="products-list">
                                        {groupedProducts?.map(
                                            ({ title, items }: { title: string; items: Product[] }, index) => (
                                                <div key={index} className="category-products-list">
                                                    <strong>{title}</strong>
                                                    <div className="products-checkbox-group">
                                                        {items.map(product => (
                                                            <ProductRow key={product.id}>
                                                                <CheckboxInput
                                                                    id={`product-${product.id}`}
                                                                    //label={product.name}
                                                                    onChange={() => onChangeProduct(product.id)}
                                                                    checked={values.products?.some(
                                                                        id => id === product.id
                                                                    )}
                                                                />
                                                                <ProductInfo>
                                                                    <ProductPhoto src={`${product.photo}?width=340`} />
                                                                    <ProductText>
                                                                        <ProductText>{product.name}</ProductText>
                                                                        <ProductDescription>
                                                                            {product.description}
                                                                        </ProductDescription>
                                                                    </ProductText>
                                                                </ProductInfo>
                                                                <small>{formatCurrency(product.price)}</small>
                                                            </ProductRow>
                                                        ))}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <MensagemErro msgErro={touched.products && errors.products} />
                                </div>
                            </InputContainer>
                        </FormSection>
                    </ContentContainer>
                    <ButtonRow justify="end">
                        <Row>
                            <TextButton onClick={lateralModalBaseRef.current?.close}>Cancelar</TextButton>
                            <ConfirmButton onClick={() => handleSubmit()}>{'Salvar'}</ConfirmButton>
                        </Row>
                    </ButtonRow>
                </OutsideContainer>
            </LateralModalBase>
            {/*
                <div className={`modal-showcase-form ${active ? 'is-active' : ''}`}>
                    <div className="Modal-bg">
                        <div className="Modal-box">
                            <div className="Modal-header">
                                <div className="Modal-title">
                                    {values.id.length ? 'Editando' : 'Novo'} agrupamento de vitrine
                                </div>
                                <div className="Modal-close" onClick={closeModal}>
                                    <FontAwesomeIcon size="lg" icon="times" color={theme.colors.primary} />
                                </div>
                            </div>
                            <div className="Modal-body">
                                <ModalLoading visible={isSubmitting || products.loading} />

                                <ModalMessage
                                    ref={modalMessageRef}
                                    title={modalMessage.title}
                                    message={modalMessage.message}
                                    textButton={modalMessage.textButton}
                                    onClose={closeModal}
                                />

                                <div className="Modal-content">
                                    <div className="products-checkbox-container">
                                        <label className="products-label">Selecione os produtos para adicionar</label>

                                        <div className="products-list">
                                            {groupedProducts.map(
                                                ({ title, items }: { title: string; items: Product[] }, index) => (
                                                    <div key={index} className="category-products-list">
                                                        <strong>{title}</strong>
                                                        <div className="products-checkbox-group">
                                                            {items.map(product => (
                                                                <div className="product-row" key={product.id}>
                                                                    <CheckboxInput
                                                                        id={`product-${product.id}`}
                                                                        label={product.name}
                                                                        onChange={() => onChangeProduct(product.id)}
                                                                        checked={values.products.some(
                                                                            id => id === product.id
                                                                        )}
                                                                    />
                                                                    <small>{formatCurrency(product.price)}</small>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <MensagemErro msgErro={touched.products && errors.products} />
                                    </div>
                                </div>

                                <div className="Modal-buttons">
                                    <button className="Modal-button outline" onClick={closeModal}>
                                        Cancelar
                                    </button>
                                    <button className="Modal-button primary" type="submit" onClick={() => handleSubmit()}>
                                        {values.id.length ? 'Salvar' : 'Adicionar'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            */}
        </>
    )
}

export default memo(forwardRef(ModalProductsShowcase))
