import {
    PerformanceCardContainer,
    PerformanceCardContent,
    PerformanceSubtitle,
    PerformanceTitle,
} from './performance-card.styles'

interface Props {
    title: string
    subtitle?: string
    fitHeight?: boolean
    style?: React.CSSProperties
}

const PerformanceCard: React.FC<Props> = ({ title, subtitle, children, style, fitHeight }) => {
    return (
        <PerformanceCardContainer className="performance-card" style={style} fitHeight={fitHeight}>
            <PerformanceTitle>{title}</PerformanceTitle>
            <PerformanceSubtitle>{subtitle}</PerformanceSubtitle>
            <PerformanceCardContent>{children}</PerformanceCardContent>
        </PerformanceCardContainer>
    )
}

export default PerformanceCard
