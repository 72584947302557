import { Fragment, memo } from 'react'
import { useHistory } from 'react-router'

import { format } from 'date-fns'

import { LoadingMessage, LoadingMoreButton } from 'components/_common'

import { useAuth } from 'hooks'
import { PerformanceFilters } from 'types'
import { IReportOrder } from 'types/report-order'

import {
    CustomerName,
    DetailsItem,
    OrderClient,
    OrderDate,
    OrderId,
    OrderSmallValue,
    OrderTable,
    TableContainer,
    TableContent,
    TableHeader,
    TableItem,
    ItemButton,
    ButtonText,
    EmptyMessageContainer,
    EmptyImage,
    EmptyMessage,
    ActionHeader,
    ActionTitle,
    ButtonsContainer,
    ActionButton,
} from './period-orders-table2.styles'

interface Props {
    filters: PerformanceFilters
    loading: boolean
    onLoadMore(): void
    orders: IReportOrder[]
    total: number
    exportClick?(): void
}

const PeriodOrdersTable2: React.FC<Props> = memo(({ loading, onLoadMore, orders, total, exportClick }) => {
    const { basePath, user } = useAuth()
    const history = useHistory()

    const hasReportStoresPermission = user?.isMallManager || user?.isManager || user?.isFinancial

    return (
        <TableContainer>
            <OrderTable>
                <ActionHeader>
                    <ActionTitle>Pedidos com Problema de Coleta</ActionTitle>
                    {hasReportStoresPermission && orders.length ? (
                        <ButtonsContainer>
                            <ActionButton onClick={exportClick}>Exportar Relatório</ActionButton>
                        </ButtonsContainer>
                    ) : null}
                </ActionHeader>
                <TableHeader>
                    <OrderId>ID</OrderId>
                    <OrderClient>Pedido</OrderClient>
                    <OrderClient>Cliente e localidade</OrderClient>
                    <OrderDate>Criação do Pedido</OrderDate>
                    <OrderDate>Pronto p/ Coleta</OrderDate>
                    <OrderDate>Coleta Improdutiva</OrderDate>
                    <OrderDate>Solução da Coleta</OrderDate>
                    <OrderSmallValue></OrderSmallValue>
                </TableHeader>
                <TableContent>
                    {loading && orders.length === 0 ? (
                        <LoadingMessage />
                    ) : orders.length > 0 ? (
                        <>
                            {orders.map((order, index) => (
                                <TableItem key={index}>
                                    <OrderId>
                                        <span>ID:</span> #{order.id}
                                    </OrderId>
                                    <OrderClient>
                                        <span>Pedido:</span>
                                        <div>{order.store.name}</div>
                                        <div className="row-info">{order.reference_name}</div>
                                        <div className="row-info">#{order.reference_id}</div>
                                    </OrderClient>

                                    <OrderClient>
                                        <CustomerName>
                                            <span>Cliente e localidade:</span>
                                            {order?.customer?.name}
                                        </CustomerName>
                                        <div className="row-info">
                                            {order.address.street}, {order.address.number}, {order.address.neighborhood}
                                        </div>
                                    </OrderClient>

                                    <OrderDate>
                                        <span>Criação do Pedido:</span>
                                        <div>
                                            {order.created_at ? format(order.created_at, 'DD/MM/YYYY [às] HH:mm') : '-'}
                                            <div className="row-info">
                                                {!!order.creator && <div>Criado por {order.creator.first_name}</div>}
                                            </div>
                                        </div>
                                    </OrderDate>

                                    <OrderDate>
                                        <span>Pronto p/ Coleta:</span>
                                        {order.end_production
                                            ? format(order.end_production, 'DD/MM/YYYY [às] HH:mm')
                                            : '-'}
                                    </OrderDate>

                                    <OrderDate>
                                        {order.problems.map((problem, i) => (
                                            <div key={i}>
                                                <div>
                                                    {problem.created_at
                                                        ? format(problem.created_at, 'DD/MM/YYYY [às] HH:mm')
                                                        : '-'}
                                                </div>
                                                {hasReportStoresPermission && !!problem.creator && (
                                                    <div className="row-info">
                                                        Relatado por {problem.creator.first_name}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </OrderDate>

                                    <OrderDate>
                                        {order.problems.map((problem, i) => (
                                            <div key={i}>
                                                <div>
                                                    {problem.canceled_at || problem.solved_at
                                                        ? format(
                                                              problem.canceled_at || problem.solved_at,
                                                              'DD/MM/YYYY [às] HH:mm'
                                                          )
                                                        : '-'}
                                                </div>
                                                {!!problem.solver && (
                                                    <>
                                                        <div className="row-info">
                                                            {problem.canceled_at
                                                                ? 'Falso Positivo Relatado por'
                                                                : 'Resolvido por'}
                                                        </div>
                                                        <div>{problem.solver.first_name}</div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </OrderDate>

                                    <OrderSmallValue>
                                        {basePath?.length ? (
                                            <ItemButton
                                                onClick={() => {
                                                    location.search
                                                    history.push(location.search + `&modal-order-id=${order.id}`)
                                                }}
                                            >
                                                <DetailsItem />
                                                <ButtonText>Ver detalhes</ButtonText>
                                            </ItemButton>
                                        ) : null}
                                    </OrderSmallValue>
                                </TableItem>
                            ))}
                            {orders.length < total && <LoadingMoreButton loading={loading} onClick={onLoadMore} />}
                        </>
                    ) : (
                        <EmptyMessageContainer>
                            <EmptyImage />
                            <EmptyMessage>Nenhum relatório localizado</EmptyMessage>
                        </EmptyMessageContainer>
                    )}
                </TableContent>
            </OrderTable>
        </TableContainer>
    )
})

export { PeriodOrdersTable2 }
