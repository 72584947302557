import styled from 'styled-components'

const ContainerProblem = styled.div<{ color?: string; backgroundColor?: string }>`
    display: flex;
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    gap: 0.25rem;
    padding: 3px;
    border-radius: 4px;
    width: fit-content;
    cursor: pointer;
    .lg-icon {
        color: ${({ color }) => color};
        font-size: 0.625rem;
    }
    .text {
        color: ${({ color }) => color};
        font-weight: 600;
        font-size: 0.625rem;
    }
`

export { ContainerProblem }
