import { createContext, useContext, useEffect, useMemo, useReducer } from 'react'

import { ActionModalProps } from 'components/_common/action-modal/action-modal'

export type ModalConfirmation = Pick<
    ActionModalProps,
    | 'title'
    | 'subtitle'
    | 'type'
    | 'modalIcon'
    | 'isActive'
    | 'children'
    | 'hasOutlineButton'
    | 'leftButtonClick'
    | 'leftButtonText'
    | 'leftButtonColor'
    | 'rightButtonClick'
    | 'rightButtonText'
    | 'rightButtonColor'
    | 'rowMode'
    | 'hidenButtons'
>

export type ModalError = Pick<
    ActionModalProps,
    'title' | 'subtitle' | 'type' | 'isActive' | 'singleButtonText' | 'singleButtonClick'
>

export type ModalSuccess = Pick<
    ActionModalProps,
    'title' | 'subtitle' | 'type' | 'isActive' | 'singleButtonText' | 'singleButtonClick'
>

interface Snackbar {
    isActive?: boolean
    type?: 'success' | 'alert' | 'error'
    message: string
    rightSide?: boolean
}

interface State {
    actionModalProps: ActionModalProps
    displayLoading: boolean
    snackbarProps: Snackbar
}

type UIContextData = State & {
    closeActionModal(): void
    closeSnackbar(): void
    setConfirmationModal(modalProps: ModalConfirmation): void
    setErrorModal(modalProps: ModalError): void
    setLoading(visible: boolean): void
    setSnackbar(snackbarProps: Snackbar): void
    setSuccessModal(modalProps: ModalSuccess): void
}

const initialState: State = {
    snackbarProps: { isActive: false, message: '' },
    actionModalProps: { isActive: false },
    displayLoading: false,
}

type Action =
    | {
          type: 'CLOSE_ACTION_MODAL'
      }
    | {
          type: 'CLOSE_SNACK_BAR'
      }
    | {
          type: 'SET_CONFIRMATION_MODAL'
          modalProps: ModalConfirmation
      }
    | {
          type: 'SET_ERROR_MODAL'
          modalProps: ModalError
      }
    | {
          type: 'SET_LOADING'
          visible: boolean
      }
    | {
          type: 'SET_SNACKBAR'
          snackbarProps: Snackbar
      }
    | {
          type: 'SET_SUCCESS_MODAL'
          modalProps: ModalSuccess
      }

function uiReducer(state: State, action: Action) {
    switch (action.type) {
        case 'CLOSE_ACTION_MODAL':
            return {
                ...state,
                actionModalProps: initialState.actionModalProps,
            }
        case 'CLOSE_SNACK_BAR':
            return {
                ...state,
                snackbarProps: initialState.snackbarProps,
            }
        case 'SET_CONFIRMATION_MODAL':
            return {
                ...state,
                actionModalProps: action.modalProps,
            }
        case 'SET_ERROR_MODAL':
            return {
                ...state,
                actionModalProps: action.modalProps,
            }
        case 'SET_LOADING':
            return {
                ...state,
                displayLoading: action.visible,
            }
        case 'SET_SNACKBAR':
            return {
                ...state,
                snackbarProps: action.snackbarProps,
            }
        case 'SET_SUCCESS_MODAL':
            return {
                ...state,
                actionModalProps: action.modalProps,
            }
        default:
            return state
    }
}

export const UIContext = createContext<UIContextData>({} as UIContextData)

export const UIProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(uiReducer, initialState)

    const closeActionModal = () => dispatch({ type: 'CLOSE_ACTION_MODAL' })
    const closeSnackbar = () => dispatch({ type: 'CLOSE_SNACK_BAR' })

    const setConfirmationModal = (modalProps: ModalConfirmation) =>
        dispatch({
            type: 'SET_CONFIRMATION_MODAL',
            modalProps: { isActive: true, rowMode: true, hasOutlineButton: true, ...modalProps },
        })

    const setErrorModal = (modalProps: ModalError) =>
        dispatch({
            type: 'SET_ERROR_MODAL',
            modalProps: { type: 'alert', isActive: true, singleButtonText: 'Ok', ...modalProps },
        })

    const setSuccessModal = (modalProps: ModalError) =>
        dispatch({
            type: 'SET_SUCCESS_MODAL',
            modalProps: { type: 'success', isActive: true, singleButtonText: 'Ok', ...modalProps },
        })

    const setSnackbar = (snackbarProps: Snackbar) => {
        dispatch({ type: 'SET_SNACKBAR', snackbarProps: { type: 'success', isActive: true, ...snackbarProps } })

        setTimeout(() => {
            dispatch({ type: 'CLOSE_SNACK_BAR' })
        }, 3000)
    }

    const setLoading = (visible: boolean) => dispatch({ type: 'SET_LOADING', visible })

    useEffect(() => {
        if (Object.keys(state).some(key => state[key as keyof State] === true)) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [state])

    const value: UIContextData = useMemo(
        () => ({
            ...state,
            closeActionModal,
            closeSnackbar,
            setConfirmationModal,
            setErrorModal,
            setLoading,
            setSnackbar,
            setSuccessModal,
        }),
        [state]
    )

    return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export function useUI(): UIContextData {
    const context = useContext(UIContext)

    return context
}
