import { Fragment, useEffect, useCallback, useMemo, useState, memo } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, withRouter, Redirect, useLocation, useHistory } from 'react-router'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faFax,
    faAddressBook,
    faEllipsisV,
    faEye,
    faHandHoldingMedical,
    faRedo,
    faTimesCircle,
    faBars,
    faTimes,
    faPaperPlane,
    faThumbsUp,
    faMap,
    faTachometerAlt,
    faShoppingBag,
    faSignOutAlt,
    faSpinner,
    faUser,
    faUserTie,
    faStore,
    faRoad,
    faExchangeAlt,
    faEyeSlash,
    faSearch,
    faFlag,
    faUsers,
    faList,
    faColumns,
    faCube,
    faConciergeBell,
    faChevronDown,
    faCheckCircle,
    faInfoCircle,
    faArrowLeft,
    faNetworkWired,
    faExternalLinkAlt,
    faChartLine,
    faFire,
    faUserCheck,
    faArrowRight,
    faSadTear,
    faChevronRight,
    faSync,
    faCaretDown,
    faShare,
    faMapMarkerAlt,
    faDrawPolygon,
    faPlus,
    faPencilAlt,
    faExclamationTriangle,
    faGripLines,
    faTrashAlt,
    faCheck,
    faStoreAlt,
    faHashtag,
    faMoneyBill,
    faDollarSign,
    faClock,
    faChartBar,
    faSlidersH,
    faTag,
    faEdit,
    faUserFriends,
    faMobileAlt,
    faTags,
    faCog,
    faBullseye,
    faMotorcycle,
    faBicycle,
    faPlusCircle,
    faLock,
    faChevronLeft,
    faBan,
    faThumbsDown,
    faExpandArrowsAlt,
    faExpand,
    faDownload,
    faFileDownload,
    faExclamationCircle,
    faSort,
    faHome,
    faBiking,
    faDotCircle,
    faBullhorn,
    faBatteryFull,
    faBatteryThreeQuarters,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryEmpty,
    faSignal,
    faMobile,
    faRobot,
    faStopwatch,
    faBolt,
    faTruck,
    faCar,
    faClipboardList,
    faCreditCard,
    faCashRegister,
    faBomb,
    faDolly,
    faLink,
    faBoxes,
    faWaveSquare,
    faCaretSquareDown,
    faCircle,
    faMinusCircle,
    faArrowCircleLeft,
    faSatellite,
    faFileInvoice,
    faShuttleVan,
    faListAlt,
    faCompressArrowsAlt,
    faHandshake,
    faFilePdf,
    faFile,
    faImage,
    faMapPin,
    faClone,
    faMapMarkedAlt,
    faFileExcel,
    faMagic,
    faPaperclip,
    faArchive,
    faArrowUp,
    faArrowDown,
    faPrint,
    faRetweet,
} from '@fortawesome/free-solid-svg-icons'
import { useUI } from 'contexts'
import jwtDecode from 'jwt-decode'
import { ModalLateralOrder } from 'modals/modal-lateral-order/modal-lateral-order'

import { MainMenu, MallSettingsModal } from 'components/_common'
import ActionModal from 'components/_common/action-modal'
import DesktopNavigationBar from 'components/_common/desktop-navigation-bar/desktop-navigation-bar'
import ModalLoading from 'components/modal-loading'

import AllowedDeliveriesPage from 'containers/allowed-deliveries-page/allowed-deliveries-page'
import AreaPage from 'containers/area-page/area-page'
import DebitNotesPage from 'containers/debit-notes-page/debit-notes-page'
import GeneralReportPage from 'containers/general-report-page/general-report-page'
import HeatmapPage from 'containers/heatmap-page/heatmap-page'
import { ImportPage } from 'containers/import-page/import-page'
import IndividualReportPage from 'containers/individual-report-page/individual-report-page'
import LoginPage from 'containers/login-page/login-page'
import { InPersonAttendanceArea } from 'containers/mall-management/pages/inperson-attendance-area/inperson-attendance-area'
import { PickerHours } from 'containers/mall-management/pages/picker-hours/picker-hours'
import { PickerManagement } from 'containers/mall-management/pages/picker-management/picker-management'
import { StoreRates } from 'containers/mall-management/pages/store-rates/store-rates'
import MallsGeneralVision from 'containers/malls-general-vision/malls-general-vision'
import MarketplaceManagement from 'containers/marketplace-management/marketplace-management'
import MercadooBagsListing from 'containers/mercadoo-bags-listing/mercadoo-bags-listing'
import NewGeneralVision from 'containers/new-general-vision/new-general-vision'
import PasswordRecoveryEmail from 'containers/new-password-recovery/password-recovery-email/password-recovery-email'
import PasswordRecoveryLink from 'containers/new-password-recovery/password-recovery-link/password-recovery-link'
import PasswordRecoveryReset from 'containers/new-password-recovery/password-recovery-reset/password-recovery-reset'
import PasswordRecoverySuccess from 'containers/new-password-recovery/password-recovery-success/password-recovery-success'
import NewStoreGeneralVision from 'containers/new-store-general-vision/new-store-general-vision'
import OrdersListingPage from 'containers/orders-listing-page/orders-listing-page'
import OrdersProductionPage from 'containers/orders-production-page/orders-production-page'
import PerformancePage from 'containers/performance-page/performance-page'
import PerformanceProblemCollect from 'containers/performance-problem-collect/performance-problem-collect'
import PickerCollectInProgress from 'containers/picker-collect-in-progress/picker-collect-in-progress'
import PickerDeliverOnDispatch from 'containers/picker-deliver-on-dispatch/picker-deliver-on-dispatch'
import PickerGeneralVision from 'containers/picker-general-vision/picker-general-vision'
import PickerReturnRoute from 'containers/picker-return-route/picker-return-route'
import PickerRouteCreation from 'containers/picker-route-creation/picker-route-creation'
import { RateChainList } from 'containers/rate-chain-list/rate-chain-list'
import ReturnRateCreation from 'containers/return-rate-creation/return-rate-creation'
import ReturnRatesListing from 'containers/return-rates-listing/return-rates-listing'
import RoutesListingPage from 'containers/routes-listing-page/routes-listing-page'
import StoreChargeAreas from 'containers/store-charge-areas/store-charge-areas'
import UnityPage from 'containers/unity-page/unity-page'
import { ViewImportPage } from 'containers/view-import-page/view-import-page'
import { ViewRomaneioPage } from 'containers/view-romaneio-page/view-romaneio-page'

import { getTokenPlooga } from 'helpers/plooga-utils'
import { useAuth } from 'hooks'
import { updateIntegration } from 'store/reducers/integrations/integrations'

import OfflineScreen from '../../components/offline-screen/offline-screen'
import api from '../../services/api'
import { AuthThunk } from '../../store/thunks'
import AdministrationPage from '../administration-page/administration-page'
import ChargeAreaCreation from '../charge-area-creation/charge-area-creation'
import ChargeAreasListing from '../charge-areas-listing/charge-areas-listing'
import ConfigMallRateChain from '../config-mall-rate-chain/config-mall-rate-chain'
import ConfigPerformanceTimes from '../config-performance-times/config-performance-times'
// import ConfigQueueRadius from '../config-queue/config-queue'
import DelivererInfo from '../deliverer-info/deliverer-info'
import DeliverersActivations from '../deliverers-activations/deliverers-activations'
import DeliverersModeration from '../deliverers-moderation/deliverers-moderation'
import ManageDeliverers from '../deliverers-scoreboard/deliverers-scoreboard'
import DeliveryQueue from '../delivery-queue/delivery-queue'
import DynamicRateCreation from '../dynamic-rate-creation/dynamic-rate-creation'
import DynamicRatesListing from '../dynamic-rate-listing/dynamic-rate-listing'
import ExpressRateCreation from '../express-rate-creation/express-rate-creation'
import ExpressRatesListing from '../express-rates-listing/express-rates-listing'
import HomePage from '../home-page/home-page'
import IndividualPaymentReport from '../individual-payment-report/individual-payment-report'
import IntegratorsPage from '../integrators-page/integrators-page'
import MallCreation from '../mall-creation/mall-creation'
import MallManagement from '../mall-management/mall-management'
import MallsListing from '../malls-listing/malls-listing'
import ManageStaffsPage from '../manage-staffs-page/manage-staffs-page'
import NewOrderDetails from '../new-order-details/new-order-details'
import OrderDetails from '../order-details/order-details'
import OrderModeration from '../order-moderation/order-moderation'
import { PaymentsListing } from '../payments-listing/payments-listing'
import PerformanceGlobal from '../performance-pages/performance-global'
// import PerformanceLogistics from '../performance-pages/performance-logistics'
// import PerformanceRoutes from '../performance-pages/performance-routes'
import RateCreation from '../rate-creation/rate-creation'
import RatesListing from '../rates-listing/rates-listing'
import RequestDelivery from '../request-delivery/request-delivery'
import RouteCreation from '../route-creation/route-creation'
import RouteDetails from '../route-details/route-details'
import { SalesChannelsListing } from '../sales-channels-listing/sales-channels-listing'
import StaffsPage from '../staffs-page/staffs-page'
import StoneManagement from '../stone-management/stone-management'
import StoreCreation from '../store-creation/store-creation'
import StoresListing from '../stores-listing/stores-listing'

import './App.scss'
import './style.css'
import '../../themes/colors.scss'
import DashboardPage from 'containers/dashboard-page/dashboard-page'

library.add(
    faFax,
    faAddressBook,
    faClone,
    faEye,
    faTimesCircle,
    faBars,
    faTimes,
    faThumbsUp,
    faMapMarkedAlt,
    faMap,
    faShoppingBag,
    faMapPin,
    faSignOutAlt,
    faUser,
    faRoad,
    faStore,
    faStoreAlt,
    faExchangeAlt,
    faEyeSlash,
    faTimes,
    faSearch,
    faFlag,
    faUsers,
    faList,
    faPaperPlane,
    faColumns,
    faCube,
    faEllipsisV,
    faConciergeBell,
    faMagic,
    faFile,
    faFileExcel,
    faChevronDown,
    faHandshake,
    faCheckCircle,
    faInfoCircle,
    faExternalLinkAlt,
    faArrowLeft,
    faShuttleVan,
    faLink,
    faTachometerAlt,
    faChartLine,
    faExpandArrowsAlt,
    faCompressArrowsAlt,
    faUserCheck,
    faThumbsUp,
    faArrowRight,
    faSadTear,
    faChevronRight,
    faCaretDown,
    faSpinner,
    faDrawPolygon,
    faTruck,
    faMinusCircle,
    faCar,
    faPlus,
    faPencilAlt,
    faFilePdf,
    faExclamationTriangle,
    faSync,
    faCaretDown,
    faMobileAlt,
    faShare,
    faMapMarkerAlt,
    faGripLines,
    faTrashAlt,
    faCheck,
    faFire,
    faHashtag,
    faUserTie,
    faDollarSign,
    faClock,
    faChartBar,
    faTag,
    faEdit,
    faUserFriends,
    faTags,
    faCog,
    faBullseye,
    faMotorcycle,
    faBicycle,
    faListAlt,
    faPlusCircle,
    faLock,
    faTrashAlt,
    faRedo,
    faHandHoldingMedical,
    faChevronLeft,
    faBan,
    faNetworkWired,
    faThumbsDown,
    faExpandArrowsAlt,
    faExpand,
    faFileDownload,
    faDownload,
    faExclamationCircle,
    faSort,
    faHome,
    faBiking,
    faMoneyBill,
    faDotCircle,
    faBullhorn,
    faBatteryFull,
    faBatteryThreeQuarters,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryEmpty,
    faSignal,
    faMobile,
    faRobot,
    faStopwatch,
    faSlidersH,
    faBolt,
    faImage,
    faClipboardList,
    faCreditCard,
    faCashRegister,
    faBomb,
    faDolly,
    faBoxes,
    faWaveSquare,
    faCaretSquareDown,
    faCircle,
    faArrowCircleLeft,
    faSatellite,
    faFileInvoice,
    faPaperclip,
    faArchive,
    faArrowUp,
    faArrowDown,
    faPrint,
    faCheckCircle,
    faRetweet
)

export const PrivateRoute = ({ component: Component, isAuthenticated, onlyAdmin, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const { user, mall, homeRoute, mallSlug, malls } = isAuthenticated
                const { pathname } = props.location
                if (!user) {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }

                if (
                    (onlyAdmin && !user?.isAdmin) ||
                    (pathname !== '/escolher-unidade' && user?.isManager && mallSlug && !malls.includes(mallSlug))
                ) {
                    return <Redirect to={{ pathname: homeRoute, state: { from: props.location } }} />
                }

                if (
                    !mall &&
                    pathname !== '/escolher-area' &&
                    !pathname.includes('entregador') &&
                    !pathname.includes('/painel-malls') &&
                    !pathname.includes('/marketplace')
                ) {
                    return <Redirect to={{ pathname: homeRoute, state: { from: props.location } }} />
                }
                return <Component {...props} />
            }}
        />
    )
}

const AdminRoute = ({ component: Component, isAuthenticated, onlyAdmin, path, ...rest }) => {
    const { user, mall, homeRoute, mallSlug, malls } = isAuthenticated

    if (!user) {
        return <Redirect to={{ pathname: '/login' }} />
    }

    if ((onlyAdmin && !user?.isAdmin) || (user?.isManager && mallSlug && !malls.includes(mallSlug))) {
        return <Redirect to={{ pathname: homeRoute }} />
    }

    return mall?.slug ? (
        <PrivateRoute {...rest} component={Component} path={`/:mall_slug/${path}`} isAuthenticated={isAuthenticated} />
    ) : null
}

const ShopkeeperRoute = ({ component: Component, isAuthenticated, onlyAdmin, path, ...rest }) => {
    const { user, mall, homeRoute, mallSlug, malls, store } = isAuthenticated

    if (!user) {
        return <Redirect to={{ pathname: '/login' }} />
    }

    if ((onlyAdmin && !user?.isAdmin) || (user?.isManager && mallSlug && !malls.includes(mallSlug))) {
        return <Redirect to={{ pathname: homeRoute }} />
    }

    return mall?.slug && store.slug ? (
        <PrivateRoute
            {...rest}
            component={Component}
            path={`/:mall_slug/:store_slug/${path}`}
            isAuthenticated={isAuthenticated}
        />
    ) : null
}

const App = memo(() => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { basePath, loading, mall, malls, store, user, mallSlug } = useAuth()
    const dispatch = useDispatch()

    const [loaded, setLoaded] = useState(false)

    const { actionModalProps, closeActionModal, displayLoading } = useUI()

    const homeRoute = useMemo(() => {
        let route = '/escolher-area'

        if (user?.isManager) {
            route = malls.length > 1 ? '/escolher-unidade' : `/${malls[0]}/rotas/visao-geral/em-andamento`
        }
        if (user?.stores?.length > 1) {
            route = '/escolher-unidade'
        }

        return route
    }, [mall, user])

    const isAuthenticated = useCallback(() => {
        return { loading, mall, store, malls, user, homeRoute, mallSlug }
    }, [loading, mall, store, user, homeRoute, mallSlug, malls])

    const checkLogin = useCallback(async () => {
        try {
            const ploogaToken = await getTokenPlooga()
            if (ploogaToken) {
                dispatch(updateIntegration({ token: ploogaToken }))
            }
            if (store?.id && mall?.id) {
                document.title = `Extranet - ${mall?.name}: ${store?.name}`
            } else {
                document.title = 'Logaroo - Extranet'
            }

            if (user?.stores?.length) {
                if (user?.stores.length === 0) {
                    const [userStore] = user?.stores
                    if (userStore?.id && pathname === '/') {
                        return history.push(`/${userStore.mall.slug}/${userStore.slug}/visao-geral/em-andamento`)
                    }
                }
                document.title = 'Logaroo - Extranet'
            }

            if (user?.token) {
                const decoded = jwtDecode(user.token)

                if (decoded.exp < new Date().getTime() / 1000) {
                    const response = await api.get('/painel/refresh')
                    localStorage.setItem('@extranet.logaroo:token', response.data.token)
                }
            }
        } catch (err) {
            console.log('CHECK LOGIN ERROR:', err)
            dispatch(AuthThunk.logout())
        }
        setLoaded(true)
    }, [dispatch, user])

    useEffect(() => {
        checkLogin()
    }, [checkLogin])

    if (!loaded) {
        return null
    }

    return (
        <>
            {!navigator.onLine && <OfflineScreen />}

            <Switch>
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/recuperar-senha" component={PasswordRecoveryEmail} />
                <Route exact path="/recuperar-senha/:email" component={PasswordRecoveryLink} />
                <Route exact path="/nova-senha/:token/:email" component={PasswordRecoveryReset} />
                <Route exact path="/senha-redefinida" component={PasswordRecoverySuccess} />
                <PrivateRoute exact path="/escolher-area" component={AreaPage} isAuthenticated={isAuthenticated()} />
                <PrivateRoute
                    exact
                    path="/escolher-unidade"
                    component={UnityPage}
                    isAuthenticated={isAuthenticated()}
                />

                <Fragment>
                    <div className="main">
                        <div className="main-left">
                            <MainMenu />
                        </div>
                        <div className="main-right">
                            <DesktopNavigationBar />
                            <MallSettingsModal isActive={false} closeClick={() => null} />

                            <Route exact path="/">
                                <Redirect to={{ pathname: homeRoute }} />
                            </Route>
                            <Route exact path="/marketplace">
                                <Redirect to={{ pathname: '/marketplace/gestao-marketplace' }} />
                            </Route>

                            <Route exact path={basePath}>
                                {store?.slug && mall?.slug ? (
                                    <Redirect to={`${basePath}/visao-geral`} />
                                ) : mall?.slug ? (
                                    <Redirect to={`${basePath}/rotas/visao-geral`} />
                                ) : null}
                            </Route>

                            {store?.slug && mall?.slug ? (
                                <ShopkeeperRoute
                                    path="visao-geral"
                                    component={NewStoreGeneralVision}
                                    isAuthenticated={isAuthenticated()}
                                />
                            ) : mall?.slug ? (
                                <AdminRoute
                                    exact
                                    path="rotas/visao-geral"
                                    component={HomePage}
                                    isAuthenticated={isAuthenticated()}
                                />
                            ) : null}

                            {/* VISÃO GERAL ROTAS - ADMIN/MANAGER */}

                            <AdminRoute
                                exact
                                path="detalhes-pedido/:order_id"
                                component={OrderDetails}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="detalhes-rota/:route_id"
                                component={RouteDetails}
                                isAuthenticated={isAuthenticated()}
                            />

                            <AdminRoute
                                exact
                                path="rotas/criar-rota"
                                component={RouteCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="moderacao-pedidos"
                                component={OrderModeration}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="rotas"
                                component={RoutesListingPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="pedidos"
                                component={OrdersListingPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="performance"
                                component={PerformancePage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="coleta-improdutiva"
                                component={PerformanceProblemCollect}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="dashboard"
                                component={DashboardPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            {/* <AdminRoute
                                        exact
                                        path="performance-rotas"
                                        component={PerformanceRoutes}
                                        isAuthenticated={isAuthenticated()}
                                    /> */}

                            <AdminRoute
                                exact
                                path="pagamentos-individuais/:id"
                                component={IndividualPaymentReport}
                                isAuthenticated={isAuthenticated()}
                            />
                            {mall.type === 'private' && (
                                <AdminRoute
                                    exact
                                    path="entregadores-autorizados"
                                    component={AllowedDeliveriesPage}
                                    isAuthenticated={isAuthenticated()}
                                />
                            )}
                            <AdminRoute
                                exact
                                path="pagamentos"
                                component={GeneralReportPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="relatorio-entregador/:id"
                                component={IndividualReportPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="gestao-mall"
                                component={MallManagement}
                                isAuthenticated={isAuthenticated()}
                            />

                            {/* Início - GESTÃO DO MALL - SUBMENU */}

                            {/* <AdminRoute
                                    exact
                                    path="area-de-roteirizacao"
                                    component={CoverageArea}
                                    isAuthenticated={isAuthenticated()}
                                />
                                <AdminRoute
                                    exact
                                    path="area-de-roteirizacao/:id"
                                    component={CoverageAreaCreation}
                                    isAuthenticated={isAuthenticated()}
                                />
                                <AdminRoute
                                    exact
                                    path="area-de-roteirizacao-criacao"
                                    component={CoverageAreaCreation}
                                    isAuthenticated={isAuthenticated()}
                                /> */}
                            <AdminRoute
                                exact
                                path="area-de-cobranca-lojista"
                                component={StoreChargeAreas}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="area-de-cobranca"
                                component={ChargeAreasListing}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="area-de-cobranca-criacao"
                                component={ChargeAreaCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="area-de-cobranca/:store/:id"
                                component={ChargeAreaCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            {/* <AdminRoute
                                exact
                                path="raio-da-fila"
                                component={ConfigQueueRadius}
                                isAuthenticated={isAuthenticated()}
                            /> */}
                            <AdminRoute
                                exact
                                path="tempos-performance"
                                component={ConfigPerformanceTimes}
                                isAuthenticated={isAuthenticated()}
                            />

                            <AdminRoute
                                exact
                                path="tarifas"
                                component={RatesListing}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifas-de-retorno"
                                component={ReturnRatesListing}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifas-de-retorno/:id"
                                component={ReturnRateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifa/:store/:id"
                                component={RateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="criar-tarifa-de-retorno"
                                component={ReturnRateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="criar-tarifa"
                                component={RateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifas-dinamicas"
                                component={DynamicRatesListing}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="criar-tarifa-dinamica"
                                component={DynamicRateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifa-dinamica/:id"
                                component={DynamicRateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifas-expressas"
                                component={ExpressRatesListing}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifa-expressa/:id"
                                component={ExpressRateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="criar-tarifa-expressa"
                                component={ExpressRateCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifa-corrente"
                                component={RateChainList}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifa-corrente/create"
                                component={ConfigMallRateChain}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="tarifa-corrente/edit/:id"
                                component={ConfigMallRateChain}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="lojas"
                                component={StoresListing}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="loja-criacao"
                                component={StoreCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="loja/:id"
                                component={StoreCreation}
                                isAuthenticated={isAuthenticated()}
                            />

                            <AdminRoute
                                exact
                                path="area-atendimento-presencial"
                                component={InPersonAttendanceArea}
                                isAuthenticated={isAuthenticated()}
                            />
                            <AdminRoute
                                exact
                                path="horarios-de-atendimento-picker"
                                component={PickerHours}
                                isAuthenticated={isAuthenticated()}
                            />

                            <AdminRoute
                                exact
                                path="area-cobranca-marketplace"
                                component={StoreRates}
                                isAuthenticated={isAuthenticated()}
                            />

                            <AdminRoute
                                exact
                                path="painel-lojistas"
                                component={StaffsPage}
                                isAuthenticated={isAuthenticated()}
                            />

                            <AdminRoute
                                exact
                                path="painel-pickers"
                                component={PickerManagement}
                                isAuthenticated={isAuthenticated()}
                            />

                            {/* Shopkeeper Routes */}

                            <ShopkeeperRoute
                                exact
                                path="fila-entregas"
                                component={DeliveryQueue}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                exact
                                path="pedidos"
                                component={OrdersListingPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                exact
                                path="detalhes-pedido/:order_id"
                                component={OrderDetails}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                exact
                                path="performance"
                                component={PerformancePage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                exact
                                path="coleta-improdutiva"
                                component={PerformanceProblemCollect}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                path="gestao-marketplace"
                                component={MarketplaceManagement}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                path="kds"
                                component={OrdersProductionPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                path="importacoes"
                                component={ImportPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                path="visualizar-importacao/:id"
                                component={ViewImportPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <ShopkeeperRoute
                                path="visualizar-romaneio/:id"
                                component={ViewRomaneioPage}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                path="/relatorio-individual/:id"
                                component={IndividualReportPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                onlyAdmin
                                path="/financeiro"
                                component={GeneralReportPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                onlyAdmin
                                path="/notas"
                                component={DebitNotesPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                onlyAdmin
                                path="/marketplace/vendas"
                                component={MercadooBagsListing}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                path="/area-de-cobranca-lojista"
                                component={StoreChargeAreas}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                path="/relatorio-geral"
                                component={GeneralReportPage}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                path="/entregar-no-dispatch"
                                component={PickerDeliverOnDispatch}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                path="/rota-devolucao"
                                component={PickerReturnRoute}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                path="/picker-rota-andamento"
                                component={PickerCollectInProgress}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                path="/criar-rota-picker"
                                component={PickerRouteCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                path="/visao-geral-picker"
                                component={PickerGeneralVision}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                path="/novo-detalhes-pedido"
                                component={NewOrderDetails}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                path="/nova-visao-geral"
                                component={NewGeneralVision}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                path="/solicitar-entrega"
                                component={RequestDelivery}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls"
                                component={MallsListing}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/visao-geral"
                                component={MallsGeneralVision}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/criar-mall"
                                component={MallCreation}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/editar-mall/:id"
                                component={MallCreation}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/canais-de-vendas"
                                component={SalesChannelsListing}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/pagamentos"
                                component={PaymentsListing}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/performance"
                                component={PerformancePage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/heatmap"
                                component={HeatmapPage}
                                isAuthenticated={isAuthenticated()}
                            />

                            {/* <PrivateRoute
                                        exact
                                        onlyAdmin
                                        path="/painel-malls/performance-logistica"
                                        component={PerformanceLogistics}
                                        isAuthenticated={isAuthenticated()}
                                    /> */}

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/performance-global"
                                component={PerformanceGlobal}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/administracao"
                                component={AdministrationPage}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/integracoes"
                                component={IntegratorsPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/integracoes/:integration_type"
                                component={IntegratorsPage}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-malls/gerenciar-lojista"
                                component={ManageStaffsPage}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                onlyAdmin
                                path="/painel-malls/gestao-stone"
                                component={StoneManagement}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                onlyAdmin
                                path="/marketplace/gestao-marketplace"
                                component={MarketplaceManagement}
                                isAuthenticated={isAuthenticated()}
                            />

                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/painel-entregadores"
                                component={ManageDeliverers}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/entregadores-pre-cadastro"
                                component={DeliverersActivations}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/entregadores-moderacao"
                                component={DeliverersModeration}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/entregadores-moderacao/:status"
                                component={DeliverersModeration}
                                isAuthenticated={isAuthenticated()}
                            />
                            <PrivateRoute
                                exact
                                onlyAdmin
                                path="/informacoes-entregador/:id"
                                component={DelivererInfo}
                                isAuthenticated={isAuthenticated()}
                            />
                        </div>
                    </div>
                </Fragment>
            </Switch>
            <ModalLateralOrder />

            <ModalLoading visible={displayLoading || loading} />
            <ActionModal {...actionModalProps} closeClick={closeActionModal} />
        </>
    )
})

export default withRouter(App)
